import { User } from '../../../types';

export enum CtaType {
	redirection = 'redirection',
	navigation = 'navigation'
}

export interface Cta {
	type:  CtaType;
	label: string;
	link: string;
	gtm_variable?: string;
}
export interface HomeItem {
	id?: string;
	title: string;
	description: string;
	img: string | undefined;
	backgroundImg?: string;
	cta: Cta;
	gtm_variable?: string;
	user?: User;
}
