import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { StyledMyTokensDashboardDialog } from '../tokens';
import { TextField, useMediaQuery, useTheme, Backdrop, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';

interface Props {
	redeemRequestId?: string;
	open: boolean;
	loading?: boolean;
	readOnly?: boolean;
	loadedComment?: string;
	onClose: () => void;
	onBack?: () => void;
	onReject: (comments:string) => void;
}

const RejectedRedeemTokensDialog = (props: Props) => {
	const { redeemRequestId, open, loading, readOnly, loadedComment, onClose, onBack, onReject } = props;

	const [comment, setComment] = useState(loadedComment ? loadedComment : '');
	const intl = useIntl();
	const theme = useTheme();
	const lessThanTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		setComment(loadedComment ? loadedComment : '');
	}, [loadedComment]);

	return (
		<StyledMyTokensDashboardDialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
			<DialogTitle
				sx={{
					fontSize: '1.5rem',
					fontWeight: 'bold',
					margin: '16px',
					marginBottom: '32px',
					padding: 0,
					borderBottom: '1px solid rgba(165, 165, 179, 1)',
				}}
			>
				<Typography sx={{ fontSize: '16px' }}>
					{
						readOnly ?
						intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.reject.redeem.title' }) :
						intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.reject.redeem.new_title' })
					}
				</Typography>
			</DialogTitle>

			<DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				{
					loading &&
					<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open>
						<CircularProgress color="inherit" />
					</Backdrop>
				}
				<Typography sx={{ fontSize: '16px' }}>
					{
						readOnly ?
						intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.reject.redeem.legend' }) :
						intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.reject.redeem.new_legend' })
					}
				</Typography>
				<TextField
					inputProps={{
						readOnly: readOnly
					}}
					multiline={true}
					rows={6}
					fullWidth
					onChange={(e) => setComment(e.target.value)}
					value={comment}
					placeholder="Comentario..."
				/>
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', gap: lessThanTablet ? 2 : 4 }}>
				<Button
					onClick={readOnly ? onClose : onBack}
					sx={{						
						width: '7.5rem',
					}}
					variant="contained"
				>
					{intl.formatMessage({ id: readOnly ? 'common.close' : 'common.back' })}
				</Button>
				{
					!readOnly &&
					<Button
						onClick={() => {
							onReject(comment)
						}}
						sx={{
							background: '#000',
							color: '#FFF',
							border: '1px solid #000',
							borderRadius: 10,
							width: lessThanTablet ? '100%' : '192px',
						}}
						variant="contained"
					>
						{intl.formatMessage({ id: 'common.confirm' })}
					</Button>
				}
			</DialogActions>
		</StyledMyTokensDashboardDialog>
	);
};

export default RejectedRedeemTokensDialog;
