import { Button, Paper, styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

const Wrapper = styled(Paper)`
	border-radius: 10px;
	overflow: hidden;
	max-width: 220px;
	width: 100%;
	max-height: 180px;
	height: 100%;
	display: inline-block;
`;

const Title = styled('text')(({ theme }) => ({
	fontSize: '0.8em',
	fill: theme.palette.text.primary,
	fontFamily: theme.typography.fontFamily,
	fontWeight: 'bold',
}));

const Svg = styled('svg')(({ theme }) => ({
	mt: 0
}));

const ActionsWrapper = styled('div')`
	display: flex;
	position: relative;
	bottom: 21px;
`;

const ActionButton = styled(LoadingButton)`
	flex: 1;
	border-radius: 0;
	padding: 10px 0;
`;

interface Action {
	title: string;
	icon?: React.ReactElement;
	loading: boolean;
	onClick: () => void;
}

interface Props {
	title?: string;
	actions?: Action[];
}

const File = (props: Props) => {
	const { title, actions } = props;

	return (
		<Wrapper>
			<Svg width="100%" height="154" viewBox="0 0 223 185" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="223" height="185" rx="10" fill="#C4C4C4" fillOpacity="0.13" />
				<path
					d="M19.2716 23.5103C19.2716 17.9874 23.7488 13.5103 29.2716 13.5103H194.646C200.169 13.5103 204.646 17.9875 204.646 23.5103V153H19.2716V23.5103Z"
					fill="white"
				/>
				{title && (
					<Title x="68" y="42">
						{title.length > 21 ? (
							<>
							<tspan x="68" y="35">{title.substring(0, title.lastIndexOf(' '))}</tspan>
							<tspan x="68" dy="1.2em">{title.split(' ').pop()}</tspan>
							</>
							) : (title)}
						
					</Title>
				)}
				<rect x="37.6255" y="62.1482" width="152.337" height="16.5185" rx="5" fill="#C4C4C4" fillOpacity="0.13" />
				<rect x="37.6255" y="87.8436" width="152.337" height="16.5185" rx="5" fill="#C4C4C4" fillOpacity="0.13" />
				<rect x="37.6255" y="117.21" width="152.337" height="16.5185" rx="5" fill="#C4C4C4" fillOpacity="0.13" />
				<circle cx="43.5906" cy="37.8292" r="11.4712" fill="black" />
				<path
					d="M46.6746 31.6849C45.2496 30.9167 43.5744 30.7396 42.0174 31.1927C40.4604 31.6458 39.1492 32.692 38.3722 34.1011C37.5953 35.5102 37.4162 37.1668 37.8745 38.7064C38.3327 40.246 39.3907 41.5426 40.8157 42.3109V42.3163L46.7554 45.3611L47.5299 43.8846L42.0685 40.9186C42.0051 40.8906 41.9418 40.8603 41.8795 40.8279C40.9851 40.3701 40.2817 39.6162 39.8921 38.6978C39.5026 37.7794 39.4516 36.7549 39.7482 35.8031C40.0447 34.8514 40.67 34.0329 41.5147 33.4905C42.3594 32.9482 43.3699 32.7165 44.3698 32.8359C45.3697 32.9553 46.2954 33.4181 46.9853 34.1437C47.6751 34.8693 48.0854 35.8114 48.1443 36.8056C48.2033 37.7998 47.9072 38.7829 47.3077 39.5832C46.7083 40.3836 45.8436 40.9502 44.8647 41.1843L46.8931 42.312C47.8492 41.773 48.6406 40.989 49.1838 40.0426C49.727 39.0961 50.0019 38.0222 49.9795 36.9342C49.9572 35.8462 49.6384 34.7842 49.0567 33.8604C48.475 32.9365 47.6521 32.185 46.6746 31.6849"
					fill="white"
				/>
			</Svg>
			{actions?.length && (
				<ActionsWrapper>
					{actions?.map((action) => (
						<ActionButton
							key={action.title}
							variant="contained"
							startIcon={action.icon}
							onClick={action.onClick}
							loading={action.loading}
							sx={{
								'& .MuiLoadingButton-loadingIndicator': {
									ml: 4,
								},
							}}
						>
							{action.loading ? 'Procesando...' : action.title}
						</ActionButton>
					))}
				</ActionsWrapper>
			)}
		</Wrapper>
	);
};

export default File;
