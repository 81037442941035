import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import { useIntl } from 'react-intl';
import { Box, Dialog, Grid, Typography, debounce } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { useUpdateTokensContext } from '../../../../contexts/updateTokensContext';
import client from '../../../../clients/client';
import { StyledMyTokensDashboardDialog } from '../tokens-seller';
import FilterTextField from '../../../FormTextField';
import DatePicker from '../../../date-picker';
import InputContainer from '../InputContainer';
import { formatNumberWithCurrency } from '../../../../utils/data-utils';

interface Props {
	open: boolean;
	onClose: () => void;
	agreementData: {
		pricingId: string;
		validity: string;
		pricing: number;
		maxCapacity: number;
		minPurchase: number;
	};
	agreementId?: string;
	token: string;
}

interface FormData {
	validity: string;
	stock: string;
}

const GenerateTokensDialog = (props: Props) => {
	const { open, onClose, agreementData, agreementId, token } = props;
	const { setRefresh } = useUpdateTokensContext();

	const intl = useIntl();
	const today = new Date();
	const tomorrow = new Date(today);
	tomorrow.setDate(tomorrow.getDate() + 1);

	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState<FormData>({
		validity: tomorrow.toISOString().substring(0, 10),
		stock: '',
	});
	const [formErrors, setFormErrors] = useState<any>({
		stock: '',
	});

	const parseDateDDMMYYYY = (dateString: string) => {
		const parts = dateString.split('/');
		return `${parts[2]}-${parts[1]}-${parts[0]}`; // Note: months are 0-based
	};

	const closeDialog = () => {
		setFormErrors({
			stock: '',
		});
		setFormData({
			validity: tomorrow.toISOString().substring(0, 10),
			stock: '',
		});
		onClose();
	};

	const validateForm = () => {
		let valid = true;
		const errors = {
			stock: '',
		};

		if (formData.stock.trim() === '') {
			errors.stock = 'La cantidad es requerida';
			valid = false;
		} else if (!/(^[0-9]+$)/g.test(formData.stock)) {
			errors.stock = 'La cantidad no es válida';
			valid = false;
		} else if (Number(formData.stock) < agreementData.minPurchase) {
			errors.stock = `La cantidad mínima es ${agreementData.minPurchase}`;
			valid = false;
		} else if (Number(formData.stock) > agreementData.maxCapacity) {
			errors.stock = `La cantidad máxima es ${agreementData.maxCapacity}`;
			valid = false;
		}

		setFormErrors(errors);
		return valid;
	};

	const fetchTokensGeneration = debounce(() => {
		setLoading(true);
		let recentValidity;
		new Date(parseDateDDMMYYYY(agreementData.validity)) > new Date(formData.validity)
			? (recentValidity = new Date(formData.validity))
			: (recentValidity = new Date(parseDateDDMMYYYY(agreementData.validity)));

		const serviceData = {
			agreement_id: agreementId,
			pricing: agreementData.pricing,
			pricing_id: agreementData.pricingId,
			validity: recentValidity,
			stock: formData.stock,
		};

		client
			.generateTokens({ token, fields: { ...serviceData } })
			.then((data) => {
				closeDialog();
				setRefresh(true);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setLoading(false));
	}, 250);

	const generateTokens = () => {
		if (validateForm()) {
			fetchTokensGeneration();
		}
	};

	return (
		<Dialog
			onClose={!!loading ? () => {} : closeDialog}
			aria-labelledby='customized-dialog-title'
			open={open}
			fullWidth
			maxWidth='md'
		>
			<DialogTitle
				sx={{
					fontSize: '18px',
					fontWeight: 700,
					padding: '0 0 14px 0',
					marginBottom: '14px',
					borderBottom: '1px solid #C6C6C6',
				}}
			>
				{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.generate_tokens.title' })}
			</DialogTitle>

			<DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3, padding: 0, alignItems: 'center' }}>
				{!!loading ? (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '70vh',
							width: '100%',
							color: 'grey.500',
						}}
					>
						<CircularProgress color='inherit' />
					</Box>
				) : (
					<>
						<Grid container spacing={2} paddingX={1}>
							<Grid item xs={12} md={6}>
								<InputContainer
									title={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.generate_tokens.actual_price' })}
									titleExtraStyles={{ fontSize: '14px' }}
								>
									<FilterTextField value={formatNumberWithCurrency(agreementData.pricing)} disabled />
								</InputContainer>
							</Grid>
							<Grid item xs={12} md={6}>
								<InputContainer
									title={intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.generate_tokens.price_validity' })}
									titleExtraStyles={{ fontSize: '14px' }}
								>
									<FilterTextField value={agreementData.validity} disabled />
								</InputContainer>
							</Grid>
							<Grid item xs={12} md={6}>
								<InputContainer
									title={intl.formatMessage({
										id: 'mytokens.seller.dashboard.dialog.generate_tokens.to_generate_quantity',
									})}
									titleExtraStyles={{ fontSize: '14px' }}
								>
									<FilterTextField
										value={formData.stock}
										type='number'
										InputProps={{
											inputProps: { min: 0 },
										}}
										error={!!formErrors.stock}
										helperText={formErrors.stock}
										onChange={(e) => {
											setFormData({
												...formData,
												stock: e.target.value,
											});
										}}
									/>
								</InputContainer>
								<Typography
									sx={{
										color: '#7E7E82',
										width: '100%',
										marginTop: 1,
									}}
								>
									{intl.formatMessage(
										{
											id: 'mytokens.seller.dashboard.dialog.generate_tokens.availability',
										},
										{ tokensAvailabilityQuantity: agreementData.maxCapacity }
									)}
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<InputContainer
									title={intl.formatMessage({
										id: 'mytokens.seller.dashboard.dialog.generate_tokens.generated_tokens_validity',
									})}
									titleExtraStyles={{ fontSize: '14px' }}
								>
									<DatePicker
										value={new Date(formData.validity)}
										onChange={(value) => {
											setFormData({
												...formData,
												//@ts-ignore
												validity: value?.toISOString()?.substring(0, 10),
											});
										}}
										variant='dialog'
										min={tomorrow}
									/>
								</InputContainer>
							</Grid>
							<Grid item xs={12}>
								<Box
									sx={{
										position: 'relative',
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										borderRadius: '10px',
										backgroundColor: '#0047FF31',
										padding: '1rem 2rem 1rem 4rem',
									}}
								>
									<InfoOutlinedIcon sx={{ position: 'absolute', top: '1rem', left: '2rem', color: '#0047FF' }} />
									<Typography sx={{ fontWeight: 600, color: '#0047FF' }}>
										{intl.formatMessage({
											id: 'mytokens.seller.dashboard.dialog.generate_tokens.footer.title',
										})}
									</Typography>
									<Typography sx={{ fontWeight: 400, color: '#0047FF' }}>
										{intl.formatMessage({
											id: 'mytokens.seller.dashboard.dialog.generate_tokens.footer.list_1',
										})}
									</Typography>
									<Typography sx={{ fontWeight: 400, color: '#0047FF' }}>
										{intl.formatMessage({
											id: 'mytokens.seller.dashboard.dialog.generate_tokens.footer.list_2',
										})}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</>
				)}
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center', padding: '10px 16px!important' }}>
				<Button
					disabled={!!loading}
					onClick={closeDialog}
					sx={{
						width: '162px',
					}}
					variant='outlined'
				>
					{intl.formatMessage({ id: 'common.close' })}
				</Button>
				<Button
					disabled={!!loading}
					onClick={generateTokens}
					sx={{
						width: '162px',
					}}
					variant='contained'
				>
					{intl.formatMessage({ id: 'mytokens.seller.dashboard.dialog.generate_tokens.footer.button' })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default GenerateTokensDialog;
