import { Stars, Help, InfoOutlined } from '@mui/icons-material';
import emotionStyled from '@emotion/styled';
import { Paper, PaperProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { red, green, yellow, blue } from '@mui/material/colors';
import blackGiftSVG from '../assets/black-gift.svg';
import greenGiftSVG from '../assets/green-gift.svg';

const GiftIcon = emotionStyled.img`
    width: 20px;
    height: 20px;
`;

interface InfoMarkerProps extends PaperProps {
	message: string | ReactNode;
	children?: ReactNode;
	icon?: 'gift' | 'star' | 'help' | 'info';
	colorScheme: 'success' | 'error' | 'info' | 'warning';
	variation?: 'banner';
}

const InfoMarker: React.FC<InfoMarkerProps> = ({ message, children, icon, colorScheme, variation, ...props }) => {

	const setColors = (): string[] => {
		switch (colorScheme) {
			case 'success':
				return [green[50], '#069C54'];

			case 'error':
				return [red[50], '#9c1006'];

			case 'warning':
				return [yellow[50], '#9c9506'];

			default:
				return [blue[50], '#406AFF'];
		}
	};

	const colors = setColors();

	return (
		<Paper
			elevation={0}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				py: '1rem',
				px: '0.5rem',
				backgroundColor: colors[0],
				alignItems: 'center',
				color: colors[1],
				borderLeft: variation === 'banner' ? '' : `4px solid ${colors[1]}`,
				...props.sx,
			}}
		>
			{icon && icon === 'gift' ? (
				<GiftIcon src={colorScheme === 'success' ? greenGiftSVG : blackGiftSVG} alt="gift-icon" />
			) : icon === 'help' ? (
				<Help sx={{ color: colors[1] }}></Help>
			) : icon === 'star' ? (
				<Stars sx={{ color: colors[1] }}></Stars>
			) : icon === 'info' ? (
				<InfoOutlined sx={{ color: colors[1] }}></InfoOutlined>
			) : null}

			{message ? (
				<Typography ml={'1rem'} variant="body2" color={colors[1]} className='font-semibold'>
					{message}
				</Typography>
			) : (
				children
			)}
		</Paper>
	);
};

export default InfoMarker;
