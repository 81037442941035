import { LoadingButton } from '@mui/lab';
import { Alert, Box, InputLabel, TextField, Typography, styled } from '@mui/material';

export const SeparatedLabel = styled(InputLabel)`
	margin-top: 20px;
	font-size: 14px;
	color: grey.600;
`;

export const StyledTextField = styled(TextField)({
	'&& .MuiInputBase-root': {
		borderRadius: '4px',
		height: '37px',
	},
});

export const StyledNumberField = styled(TextField)({
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		'-webkit-appearance': 'none',
		margin: 0,
	},
	'& input[type=number]': {
		'-moz-appearance': 'textfield',
	},
});

export const StyledAlert2 = styled(Alert)(({ theme }) => ({
	border: 'none',
	backgroundColor: '#D8DCED',
	borderRadius: '10px',
	color: '#222',
}));

export const GridBox = styled(Box)(({ theme }) => ({
	'& #noPaddingGrid': {
		[theme.breakpoints.down('md')]: {
			paddingTop: '20px',
		},
	},
	'& #noPaddingDownGrid': {
		paddingTop: '30px',
	},
	borderRadius: '8px',
	backgroundColor: '#FFFFFF',
	padding: '30px 40px 30px 40px',
}));

export const NextButton = styled(LoadingButton)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		width: '40%',
	},
	[theme.breakpoints.up('md')]: {
		minWidth: '120px',
	},
}));

export const TypographyTitle = styled(Typography)({
	fontWeight: 'bold',
	marginLeft: '24px',
	marginBottom: '36px',
});
