import { Launch } from "@mui/icons-material";
import { Box, IconButton, LinearProgress, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmptySupportedTypography from "./empty-supported-typography";
import ClientBillingInfoDialog, { ClientData } from "./client-billing-info-dialog";
import { BusinessName } from "../types";
import businessNameClient from "../clients/businessNameClient";
import { useRecoilValue } from "recoil";
import userState from '../atoms/user-state';

interface ClientInfoProps {
    constructor_id?: string;
    customMessage?: string;
    businessName?: BusinessName;
    businessNameId?: string;
    showButton?: boolean;
}

const ClientInfoComponent: React.FC<ClientInfoProps> = (props) => {
    const { constructor_id, customMessage, businessNameId, showButton = true } = props;
    const businessNameParam = props.businessName;

    const [isLoading, setIsLoading] = useState(true);
    const [showBillingModal, setShowBillingModal] = useState(false);

    const user = useRecoilValue(userState);
    const [businessName, setBusinessName] = useState<BusinessName>();
    const [clientData, setClientData] = useState<ClientData>()

    const fetchDefaultBusinessName = async () => {
        if (businessNameParam) {
            setBusinessName(businessNameParam);
            setClientData({
                name: businessNameParam.business_social || '',
                taxId: businessNameParam.cuit || 0
            })

            return;
        } else if (businessNameId) {
            const response = await businessNameClient.getBusinessName({ user: user, id: businessNameId, all: true });

            if (response) {
                setBusinessName(response[0]);

                setClientData({
                    name: response[0].business_social,
                    taxId: response[0].cuit,
                })
            }
        } else if (constructor_id) {
            const response = await businessNameClient.getDefaultBusinessName({ user: user, constructor_id: constructor_id });

            if (response) {
                setBusinessName(response);

                setClientData({
                    name: response.business_social,
                    taxId: response.cuit
                })
            }
        }
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            fetchDefaultBusinessName().finally(() => setIsLoading(false));
        }

        return () => { mounted = false };
    }, [constructor_id, user.token]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {isLoading ?
                (<LinearProgress sx={{ backgroundColor: '#406AFF', height: '2px' }} />) :
                (<Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {customMessage &&
                        <Typography variant={'body2'}>
                            <Link onClick={() => setShowBillingModal(true)} className="cursor-pointer" underline="none">
                                {customMessage}
                            </Link>
                        </Typography>
                    }
                    {!customMessage && businessName &&
                        <EmptySupportedTypography variant="subtitle1" display="inline" color="#7E7E82">
                            {businessName.business_social}
                        </EmptySupportedTypography>}
                    {businessName && clientData &&
                        <>
                            {showButton &&
                                <IconButton onClick={() => setShowBillingModal(true)} className="p-0 ml-1" color="primary" disableRipple>
                                    <Launch fontSize={'small'}></Launch>
                                </IconButton>
                            }
                            <ClientBillingInfoDialog open={showBillingModal} onClose={() => setShowBillingModal(false)} clientData={clientData} files={businessName.files} />
                        </>
                    }
                </Box>)
            }
        </Box>
    );
};

export default ClientInfoComponent;