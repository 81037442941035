import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	styled,
} from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import FullScreenLoading from './full-screen-loading';

const LabelButton = styled(Button)`
	color: blue;
`;

const SeparatedTextField = styled(TextField)`
	margin-top: 1rem;
`;

interface DialogContent {
	title: string;
	text?: string;
}

interface Props {
	icon?: JSX.Element;
	color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
	confirmation?: DialogContent;
	securityCheck?: DialogContent;
	success?: DialogContent;
	label: string;
	action: (password?: string) => Promise<any>;
	onSuccess?: () => void;
}

const ActionButton = (props: Props) => {
	const intl = useIntl();

	const { icon, confirmation, securityCheck, success, label, color, action, onSuccess } = props;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<Error>();

	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [securityCheckOpen, setSecurityCheckOpen] = useState(false);
	const [successOpen, setSuccessOpen] = useState(false);

	const [password, setPassword] = useState<string>();

	const handleClick = () => {
		if (!!confirmation) {
			setConfirmationOpen(true);
		} else {
			handleConfirmation();
		}
	};

	const handleConfirmation = () => {
		if (!!securityCheck) {
			setSecurityCheckOpen(true);
		} else {
			handleSecurityChecked();
		}
	};

	const handleSecurityChecked = () => {
		setLoading(true);

		action(password)
			.then(() => {
				if (success) {
					setSuccessOpen(true);
				} else {
					handleSuccess();
				}
			})
			.catch((error) => setError(error))
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRetry = () => {
		setError(undefined);
		handleConfirmation();
	};

	const handleCancel = () => {
		setConfirmationOpen(false);
		setSecurityCheckOpen(false);
		setSuccessOpen(false);
		setError(undefined);
	};

	const handleSuccess = () => {
		handleCancel();
		onSuccess && onSuccess();
	};

	return (
		<>
			{loading && <FullScreenLoading />}
			{icon && (
				<Tooltip placement="top" title={label}>
					<IconButton color={color} onClick={handleClick}>
						{icon}
					</IconButton>
				</Tooltip>
			)}
			{!icon && <LabelButton onClick={handleClick}>{label}</LabelButton>}
			{confirmation && (
				<Dialog
					open={confirmationOpen}
					onClose={handleCancel}
					fullWidth
					maxWidth="xs"
					sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3 } }}
				>
					<DialogTitle textAlign="center">
						<Typography variant="h4">{confirmation.title}</Typography>
					</DialogTitle>
					<DialogContent className="text-center">
						<Typography variant="body1">{confirmation.text}</Typography>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center', gap: 1, pt: 3 }}>
						<Button onClick={handleCancel} variant="outlined" sx={{ width: '7.5rem' }}>
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<Button onClick={handleConfirmation} variant="contained" sx={{ width: '7.5rem' }}>
							{intl.formatMessage({ id: 'common.confirm' })}
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{securityCheck && (
				<Dialog
					open={securityCheckOpen}
					onClose={handleCancel}
					fullWidth
					maxWidth="xs"
					sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3 } }}
				>
					<DialogTitle textAlign="center">
						<Typography variant="h5">{securityCheck.title}</Typography>
					</DialogTitle>
					<DialogContent>
						<Typography variant="body1">{securityCheck.text}</Typography>
						<SeparatedTextField
							type="password"
							label={intl.formatMessage({ id: 'common.password' })}
							variant="outlined"
							fullWidth
							value={password}
							onChange={(event) => setPassword(event.target.value)}
						/>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center', gap: 1, pt: 3 }}>
						<Button
							onClick={handleCancel}
							variant="outlined"
							sx={{ width: '120px' }}
						>
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<Button
							onClick={handleSecurityChecked}
							variant="contained"
							sx={{ width: '120px' }}
						>
							{intl.formatMessage({ id: 'common.continue' })}
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{success && (
				<Dialog
					open={successOpen}
					onClose={handleSuccess}
					fullWidth
					maxWidth="xs"
					sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3 } }}
				>
					<DialogTitle textAlign="center">
						<Typography variant="h5">{success.title}</Typography>
					</DialogTitle>
					<DialogContent>
						<Typography variant="body1">{success.text}</Typography>
					</DialogContent>
					<DialogActions sx={{ justifyContent: 'center', gap: 1, pt: 3 }}>
						<Button
							onClick={handleSuccess}
							variant="contained"
							sx={{ width: '120px' }}
						>
							{intl.formatMessage({ id: 'common.finish' })}
						</Button>
					</DialogActions>
				</Dialog>
			)}
			<Dialog
				open={error !== undefined}
				onClose={handleCancel}
				fullWidth
				maxWidth="xs"
				sx={{ '& .MuiPaper-root': { maxWidth: '500px', p: 3 } }}
			>
				<DialogTitle textAlign="center">
					<Typography variant="h5">{intl.formatMessage({ id: 'common.error' })}</Typography>
				</DialogTitle>
				<DialogContent>
					<Typography variant="body1">{error?.message}</Typography>
				</DialogContent>
				<DialogActions sx={{ justifyContent: 'center', gap: 1, pt: 3 }}>
					<Button
						onClick={handleCancel}
						variant="outlined"
						sx={{ width: '120px' }}
					>
						{intl.formatMessage({ id: 'common.cancel' })}
					</Button>
					<Button
						onClick={handleRetry}
						variant="contained"
						sx={{ width: '120px' }}
					>
						{intl.formatMessage({ id: 'common.retry.button' })}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ActionButton;
