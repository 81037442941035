import styled from '@emotion/styled';
import { Check, Circle, ErrorOutline } from '@mui/icons-material';
import { Card, Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography, stepConnectorClasses, Box, stepLabelClasses, Tooltip, Stack, } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useMobile } from '../hooks/use-mobile';
import { useEffect, useRef, useState } from 'react';

const StepWrapper = styled('div')((props: { backgroundColor: string; animated?: boolean }) => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    backgroundColor: props.backgroundColor,
    animation: props.animated ? 'pulse-animation 2s infinite' : 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const StepCompletedIcon = styled(Check)(() => ({
    fontSize: '14px',
}));

const StepErrorIcon = styled(ErrorOutline)(() => ({
    fontSize: '14px',
}));

const StepActiveIcon = styled(Circle)(() => ({
    fontSize: '8px',
    margin: '8px',
}));

const Connector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 10px)',
        right: 'calc(50% + 10px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: 'linear-gradient(90deg, #A0C4FF, #406AFF)',
            border: 0,
            height: 1,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: 'linear-gradient(90deg, #A0C4FF, #406AFF)',
            border: 0,
            height: 1,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#fff',
        borderTopWidth: 1,
        height: 1,
    },
}));

const StepIcon = (props: StepIconProps) => {
    const { active, completed, error } = props;

    if (error) {
        return (
            <StepWrapper backgroundColor="red">
                <StepErrorIcon sx={{ color: '#fff' }} />
            </StepWrapper>
        );
    }

    if (completed) {
        return (
            <StepWrapper backgroundColor="#ACCBFF">
                <StepCompletedIcon sx={{ color: '#fff' }} />
            </StepWrapper>
        );
    }

    if (active) {
        return (
            <StepWrapper backgroundColor="#406AFF">
                <StepActiveIcon sx={{ color: '#fff' }} />
            </StepWrapper>
        );
    }

    return (
        <StepWrapper backgroundColor="#fff">
            <StepCompletedIcon sx={{ color: '#D4E4FF' }} />
        </StepWrapper>);
};

const CustomStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.alternativeLabel}`]: {
        [`&.${stepLabelClasses.completed}`]: {
            color: '#ACCBFF'
        },
        [`&.${stepLabelClasses.active}`]: {
            color: '#406AFF'
        },
        color: '#989898CC',
        fontSize: '0.81rem',
        lineHeight: '1rem',
    }
}));

export interface Status {
    label: string;
    warning?: string;
    active: boolean;
    completed: boolean;
    tooltip?: string;
    alwaysShowTooltip?: boolean;
}

interface Props {
    title?: string;
    titleLocation?: 'start' | 'center' | 'end';
    statuses: Status[];
    loading?: boolean;
    elevation?: number;
}

const StatusCardV2 = (props: Props) => {
    const {
        title,
        statuses,
        loading,
        elevation = 1,
    } = props;
    const mobile = useMobile();
    const [showTooltip, setShowTooltip] = useState(true)
    const tooltipRef = useRef<HTMLSpanElement>(null);

    const getStatusSteps = (statuses: Status[]) => {
        return statuses.map(({ label, active, completed, warning, tooltip, alwaysShowTooltip = false }) => {
            const step = (
                <Step key={label} active={active} completed={completed}>
                    <CustomStepLabel
                        StepIconComponent={StepIcon}
                        error={Boolean(warning)}
                    >
                        {label}
                        {warning && <Typography variant="caption">{warning}</Typography>}
                    </CustomStepLabel>
                </Step>
            );

            if(tooltip && active) {
                return (
                    <Tooltip
                    open={showTooltip} 
                    onClose={() => setShowTooltip(false)} 
                    onOpen={() => setShowTooltip(true)} 
                    title={tooltip} 
                    placement="top" 
                    key={label} 
                    arrow
                >
                    {step}
                </Tooltip>)
            }
            else if( tooltip && alwaysShowTooltip) {
                return <Tooltip title={tooltip} placement="top" key={label} arrow>{ step }</Tooltip>
            }
            else {
                return step
            } 
        });
    };

    const getDesktopSteps = (statuses: Status[]) => {        
        return getStatusSteps(statuses);
    }

    const getMobileSteps = (statuses: Status[]) => {
        let currentStepIndex = statuses.findIndex((status) => status.active === true);

        // if the current step is the last step, go back to the previous step
        if (currentStepIndex === statuses.length - 1) {
            currentStepIndex--;
        }

        return getStatusSteps(statuses.filter((_status, index) => {
            if (index == currentStepIndex || index == currentStepIndex + 1) {
                return true;
            }
        }));
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {          
            setShowTooltip(false);
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [tooltipRef]);

    return (
        <Card title={title} elevation={elevation} sx={{ backgroundColor: '#E7F0FF', minHeight: '145px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading ? (
                <Box sx={{ width: '100%' }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-around"
                        spacing={1}
                        width={'100%'}
                    >
                        {statuses.map((_status) => (
                            <Stack key={_status.label} direction={'column'} alignItems="center" spacing={1}>
                                <Skeleton animation="wave" variant="circular" width={20} height={20} />
                                <Skeleton animation="wave" width={60} height={20} />
                            </Stack>
                        ))}
                    </Stack>
                </Box>
            ) : (
                <Stepper
                    alternativeLabel={true}
                    connector={<Connector />}
                    orientation={'horizontal'}
                    sx={{ width: '100%' }}
                >
                    {mobile ? getMobileSteps(statuses) : getDesktopSteps(statuses)}
                </Stepper>
            )}
        </Card>
    );
};


export default StatusCardV2;

