import React, { useCallback } from 'react';
import { Button, IconButton } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import ChatBubble from '@mui/icons-material/ChatBubble';
import { useIntl } from 'react-intl';

declare global {
	interface Window {
		$crisp: any[];
	}
}

const CrispCTAButton = () => {
	const intl = useIntl();
	const theme = useTheme();
	const isXs = useMediaQuery(theme.breakpoints.down('sm'));

	const handleOnClick = useCallback(() => {
		if (window.location.hostname === 'studio.nuqlea.com') {
			window.$crisp.push(['do', 'chat:open']);
			
			(globalThis as any).dataLayer?.push({
				"event": "ns_click_boton_acopiar",
			})
		}
	}, []);

	return isXs ? (
		<IconButton size="large" className="text-blue-600 p-0" onClick={handleOnClick}>
			<ChatBubble className="w-[1.5rem] h-[1.5rem]" />
		</IconButton>
	) : (
		<Button
			size="small"
			variant="contained"
			className="text-blue-600"
			id="speak-with-nuqlea-agent-button"
			sx={{
				backgroundColor: '#EAEFFF',
				':hover': { backgroundColor: '#EAEFFF' },
				border: '1px solid #EAEFFF', // to have same height as InfoButton
				borderBottomWidth: '2px',
			}}
			startIcon={<ChatBubble />}
			onClick={handleOnClick}
		>
			{intl.formatMessage({ id: 'crisp.call_to_action_text' })}
		</Button>
	);
};

export default CrispCTAButton;
