import { Count } from '../components/stepper-card';
import { DataRow } from '../components/table-card';
import { colorsByStatus } from '../constants';
import { FileWithContent, FilterValues, List, ListCount } from '../types';

export const getCounts = (counts?: ListCount[]): Count[] => {
	if (!counts) {
		return [];
	}

	return counts
		.map((count) => {
			const status = count.list_status_for_constructor || count.list_status_for_seller;

			return {
				displayName: status,
				color: colorsByStatus[status],
				value: count.status_count,
			};
		})
		.filter((count) => count.displayName !== 'No concretada');
};

export const aggregateCounts = (counts: (ListCount[] | undefined)[], statusToSkip: string[] = []): Count[] => {
	const countsByStatus: { [x: string]: number } = {};

	for (let innerCounts of counts) {
		if (!innerCounts) {
			continue;
		}

		for (let innerCount of innerCounts) {
			const status = innerCount.list_status_for_constructor || innerCount.list_status_for_seller;
			if (!countsByStatus[status]) {
				countsByStatus[status] = 0;
			}

			countsByStatus[status] += innerCount.status_count;
		}
	}

	return Object.keys(countsByStatus)
		.filter((status) => !statusToSkip.includes(status))
		.map((status) => ({
			displayName: status,
			color: colorsByStatus[status],
			value: countsByStatus[status],
		}));
};

export const calculateDownloadLink = (full_link: string) => {
	const link_parts = (full_link || '').split('/');
	const file_id = link_parts[link_parts.length - 1];

	return `${process.env.REACT_APP_DOWNLOAD_BASE_URL}/${file_id}/`;
};

export const calculateDaysColor = (days?: number, quotationType?: string) => {
	if (days === null) {
		return 'white';
	}

	if (days === 0 || days === 1) {
		return '#C8E6C9'; // green
	}

	if (days === 2) {
		return '#FFECB3'; // yellow
	}

	if (days != null && days >= 3) {
		return '#F4C7C3'; // red
	}

	if (quotationType === 'Recotización') {
		return '#CCCCCC'; // light-gray
	}

	return 'none';
};

export const applyFilter = (filters: FilterValues, lists?: List[]): List[] => {
	if (!lists) {
		return [];
	}

	return lists.filter((list) => {
		if (filters.id && !list.list_migration_id?.startsWith(filters.id)) {
			return false;
		}

		if (filters.project && list.project_name !== filters.project) {
			return false;
		}

		if (filters.client && list.client_name !== filters.client) {
			return false;
		}

		if (filters.startDate && filters.startDate > new Date(list.reception_date)) {
			return false;
		}

		if (filters.endDate && filters.endDate < new Date(list.order_closed_date)) {
			return false;
		}

		if (filters.orderDate && filters.orderDate > new Date(list.quotation_order_date)) {
			return false;
		}

		return true;
	});
};

export const getFieldValues = (field: string, lists: (List[] | undefined)[]): string[] => {
	const values = new Set<string>();

	for (let innerLists of lists) {
		if (!innerLists) {
			continue;
		}

		for (let innerList of innerLists) {
			// @ts-ignore
			const value = innerList?.[field]?.toString();

			if (!value) {
				continue;
			}

			values.add(value);
		}
	}

	return Array.from(values).sort();
};

export const defaultFieldSort = (field: string) => (a: DataRow, b: DataRow) => {
	const valueA = a[field];
	const valueB = b[field];

	return defaultSort(valueA, valueB);
};

export const defaultSort = (a: string, b: string) => {
	if (a === b) {
		return 0;
	}

	if (!a) {
		return 1;
	}

	if (!b) {
		return -1;
	}

	return a.localeCompare(b);
};

export const numberFieldSort = (field: string) => (a: DataRow, b: DataRow) => {
	const valueA = a[field];
	const valueB = b[field];

	return numberSort(valueA, valueB);
};

export const numberSort = (a: number, b: number) => {
	if (a === b) {
		return 0;
	}

	if (!a) {
		return 1;
	}

	if (!b) {
		return -1;
	}

	return a - b;
};

const memoryUnits = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const humanFileSize = (bytes: number, decimals: number = 2) => {
	if (Math.abs(bytes) < 1024) {
		return bytes + ' B';
	}

	let u = -1;
	const r = 10 ** decimals;

	do {
		bytes /= 1024;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= 1024 && u < memoryUnits.length - 1);

	return bytes.toFixed(decimals) + ' ' + memoryUnits[u];
};

export const fileToBase64 = (file: File): Promise<FileWithContent> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () =>
			resolve({
				base64: (reader.result as string).split(',').pop(),
				name: file.name,
				type: file.type,
				size: file.size,
			});
		reader.onerror = (error) => reject(error);
	});
};

export const filesToBase64 = (file: File[]): Promise<FileWithContent[]> => {
	return Promise.all(file.map(fileToBase64));
};

export const formatCUIT = (cuit: string | number): string => {
    const stringCUIT = typeof cuit === 'number' ? cuit.toString() : cuit
    const firstPart = stringCUIT.substring(0,2)
    const secondPart = stringCUIT.substring(2,10)
    const thirdPart = stringCUIT.substring(10)
    return `${firstPart}-${secondPart}-${thirdPart}`
};

export const formatNumberWithCurrency = (price: number | undefined, digitsQuantity?: number): string => {
	if (typeof price === 'number') {
	  return price.toLocaleString('es-AR', {
		style: 'currency',
		currency: 'ARS',
		minimumFractionDigits: digitsQuantity ?? 0,
		maximumFractionDigits: digitsQuantity ?? 0,
	  });
	} else {
	  return '$'
	}
};

export const formatNumberLocale = (number: number) => {
	return number.toLocaleString('es-AR');
}

/**
 * Round number to the specified number of decimals.
 * If the number of decimals are not specified, it will be rounded to 2 decimals
 * 
 * @param num 
 * @param decimals  
 * @returns number rounded to the specified number of decimals
 */
export const roundToDecimals = (num: number, decimals?: number) => {
	let factor = Math.pow(10, decimals ?? 2);
	return Math.round(num * factor) / factor;
}

/**
 * Trunc number to the specified number of decimals.
 * If the number of decimals are not specified, it will be truncated to 2 decimals
 * 
 * @param num 
 * @param decimals  
 * @returns number truncated to the specified number of decimals
 */
export const truncToDecimals = (num: number, decimals?: number) => {
	let factor = Math.pow(10, decimals ?? 2);
	return Math.trunc(preciseMultiply(num, factor)) / factor;
}

export const preciseMultiply = (num1: number, num2: number) => {
	return roundToDecimals(num1 * num2, 0);
}

export function preciseSubtract({
	decimalsPrecision,
	numbers
}: {
	decimalsPrecision?: number;
	numbers: number[];
}): number {
	// Substract all numbers
	const result = numbers.reduce((acc, num) => acc - num, numbers[0] * 2);

	// Round to the specified number of decimals or 2 decimals by default
	const factor = Math.pow(10, decimalsPrecision ?? 2);
	return Math.round(result * factor) / factor;
}

export function preciseSum({
	decimalsPrecision,
	numbers
}: {
	decimalsPrecision?: number;
	numbers: number[];
}): number {
	// Sum all numbers
	const result = numbers.reduce((acc, num) => acc + num, 0);

	// Round to the specified number of decimals or 2 decimals by default
	const factor = Math.pow(10, decimalsPrecision ?? 2);
	return Math.round(result * factor) / factor;
}