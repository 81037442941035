import { CtaType, HomeItem } from "../pages/home/domain/HomeItem";

import cotizacion from '../assets/cotizacion.png';
import acopio from '../assets/acopio-banner-home.png';
import acindarHierro from '../assets/main-banner-img.png';
import backgroundImageMainBanner from '../assets/background-img-main-banner.png';

const HomeClient = {
	getHomeData(): { banner: HomeItem; items: HomeItem[] } {
		return {
			banner: {
				id: 'home_main_banner_acindar',
				title: 'Acopio digital',
				description: 'Congelá el precio de tu hierro hoy',
				cta: {
					type: CtaType.navigation,
					label: 'Acopiá directo en Acindar',
					link: '/my-tokens/buy/quantity-select?agreement=9',
					gtm_variable: 'click_home_banner_acindar',
				},
				img: acindarHierro,
				backgroundImg: backgroundImageMainBanner
			},
			items: [
				{
					id: 'home_main_banner_ladrillos',
					title: 'Acopia ladrillos con Nuqlea',
					description: 'Congelá el valor de tu compra ahora y retirá cuando lo necesites.',
					img: acopio,
					cta: {
						type: CtaType.navigation,
						label: 'Acopiá ladrillos',
						link: '/my-tokens/buy/quantity-select?agreement=d9ac3a278 ',
						gtm_variable: 'click_home_banner_ladrillos',
					},
				},
				{
					id: 'home_main_banner_cotizar_productos',
					title: 'Cotizá los productos para tu obra',
					description: 'Compartí tus necesidades de obra y te conseguimos las mejores condiciones',
					img: cotizacion,
					cta: {
						type: CtaType.navigation,
						label: 'Cotizá',
						link: '/new-quotation',
						gtm_variable: 'click_home_banner_cotizar_productos',
					},
				},
			],
		};
	},
};


export default HomeClient