import { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useIntl } from 'react-intl';
import { InputLabel, MenuItem, Typography, styled, Alert, IconButton, TextField, FormControlLabel, Checkbox, Divider, Stack, debounce } from '@mui/material';
import FileUpload from 'react-material-file-upload';
import DatePicker from '../../../date-picker';
import { Delivery, DeliveryCompleteness, DeliveryType, RedeemRequestTypes } from '../../../../types';
import FullScreenLoading from '../../../full-screen-loading';
import client from '../../../../clients/client';
import { useDeliveryContext } from '../../../../contexts/deliveryContext';
import { useRecoilValue } from 'recoil';
import userState from '../../../../atoms/user-state';
import SideModalContainer from '../../../modals/side-modal-container';
import { useMobile } from '../../../../hooks/use-mobile';
import CloseIcon from '@mui/icons-material/Close';
import { DeliveryStatus } from '../../../../constants';
import DateFilesComments from './date-files-comments';
import DeliveryProducts from './delivery-products';

const ErrorBox = styled(Box)(({ theme }) => ({
	border: 'none',
	borderLeft: '3px solid #FF0000',
	borderRadius: '5px',
	backgroundColor: '#FF000033',
	color: '#FF0000',
	padding: '1em',
	minHeight: '3em',
	display: 'flex',
	alignItems: 'center',
}));

const DeliveredStatus = styled(Box)`
	color: #3DBE00;
	background-color: #3DBE005C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 8rem;
	width: 100%;
	text-align: center;
	padding: .1rem;
`;

const PendingDeliveryStatus = styled(Box)`
	color: #556AC3;
	background-color: #556AC35C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 8rem;
	width: 100%;
	text-align: center;
	padding: .1rem;
`;

const CanceledDeliveryStatus = styled(Box)`
	color: #FF0005;
	background-color: #FF00055C;
	font-weight: 500;
	font-size: 13px;
	border-radius: 5px;
	max-width: 8rem;
	width: 100%;
	text-align: center;
	padding: .1rem;
`;

interface Props {
	open: boolean;
	delivery: Delivery;
	deliveryType: RedeemRequestTypes.SHIPPING | RedeemRequestTypes.WITHDRAWAL;
	modalType: 'new' | 'edit' | 'view';
	onClose: (event?: object | null, reason?: string, newFile?: any) => void;
	redeemRequestProducts: any[];
	unity: string;
	redeemStep: number;
}

const deliveryIdMapper = {
	[RedeemRequestTypes.SHIPPING]: 5,
	[RedeemRequestTypes.WITHDRAWAL]: 6,
};

const SellerDeliveryModal = (props: Props) => {
	const intl = useIntl();
  	const isMobile = useMobile();
	const user = useRecoilValue(userState);
	const { open, delivery, deliveryType, modalType, onClose, redeemRequestProducts, unity, redeemStep } = props;
	
	const { baseInfo, setDeliveryModal } = useDeliveryContext();
	const [newDeliveryData, setNewDeliveryData] = useState<Delivery>(null as any);

	const [state, setState] = useState<{
		error: string;
		loading: boolean;
		deletedFiles: any[];
	}>({
		error: '',
		loading: false,
		deletedFiles: [],
	});
	const { error, loading, deletedFiles } = state;

	const redeemRequestProductsMap: Map<string, any> = useMemo(() => {
		const map = new Map();
		redeemRequestProducts.forEach((product) => {
			map.set(product.id, product);
		});
		return map;
	}, [redeemRequestProducts]);

	const fetchCreateShipping = debounce((uploadedFiles) => {
		client.createShipping({
			fields: {
				comment: newDeliveryData?.comment,
				redeem_request_id: baseInfo?.metadata?.redeem_request_id,
				delivery_type: deliveryIdMapper[deliveryType],
				status: newDeliveryData?.status,
				products: newDeliveryData?.products?.map(product => ({ ...product, quantity: product.quantity || 0 })),
				estimated_delivery_date: newDeliveryData?.estimated_delivery_date ? newDeliveryData?.estimated_delivery_date.substring(0,10) : null,
				files: uploadedFiles ? uploadedFiles : [],
				...(newDeliveryData.delivery_date ? { delivery_date: newDeliveryData?.delivery_date.substring(0,10) } : {}),
			},
			user
		}).then(() => {
			onClose(null, 'success');
			setState({ ...state, loading: false });
		})
		.catch((error) => {
			setState({ ...state, loading: false, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.create_delivery' }) });
		})
	}, 250);

	const fetchUpdateShipping = debounce((uploadedFiles) => {
		client.updateShipping({
			deliveryId: newDeliveryData?.id,
			fields: {
				completeness: null,
				tracking_code: null,
				express_name: null,
				comment: newDeliveryData?.comment || '',
				redeem_request_id: baseInfo?.metadata?.redeem_request_id,
				delivery_type: deliveryIdMapper[deliveryType],
				status: newDeliveryData?.status,
				products: newDeliveryData?.products?.map(product => ({ ...product, quantity: product.quantity || 0 })),
				estimated_delivery_date: newDeliveryData?.estimated_delivery_date ? newDeliveryData?.estimated_delivery_date.substring(0,10) : null,
				files: uploadedFiles ? uploadedFiles : [],
				files_to_delete: deletedFiles,
				...(newDeliveryData.delivery_date ? { delivery_date: newDeliveryData?.delivery_date.substring(0,10) } : {}),
			},
			user
		}).then(() => {
			setState({ ...state, loading: false });
			onClose(null, 'success');
		})
		.catch((error) => {
			setState({ ...state, loading: false, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.edit_delivery' }) });
		})
	}, 250);

	const handleSubmit = async () => {
		if (!newDeliveryData?.estimated_delivery_date) {
			setState({ ...state, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.estimated_delivery_date' }) });
			return;
		}
		if (newDeliveryData?.status === 2 && !newDeliveryData?.delivery_date) {
			setState({ ...state, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.delivery_date' }) });
			return;
		}
		if (newDeliveryData.products?.every((product) => !product.quantity)) {
			setState({ ...state, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.zero_quantity_products' }) });
			return;
		}

		if (newDeliveryData.products?.some((product) => {
			const redeemRequestProduct = redeemRequestProductsMap.get(product.redeem_request_product_id);
			const originalProductValue = delivery?.products?.find((p) => p.redeem_request_product_id === product.redeem_request_product_id)?.quantity ?? 0;
			return product.quantity > redeemRequestProduct.unassigned + originalProductValue
		})) {
			setState({ ...state, error: intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.quantity_product' }) });
			return;
		}

		setState({ ...state, loading: true });
	
		const fileToBase64 = async (file: File) => {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve((reader.result as string).split(',').pop());
				reader.onerror = (error) => reject(error);
			});
		}
	
		let uploadedFiles: any[] = [];
		if (newDeliveryData?.files && newDeliveryData?.files?.length > 0) {
			for (const element of newDeliveryData.files) {
				if (element.file === undefined || !element.updated) continue;
	
				const file = element.file as File;
				const id_doc = element.id_doc as string;
				const convertedFile = await fileToBase64(file);
	
				if (convertedFile) {
					const uploadedFile = await client.uploadRedeemRequestDriveFile({
						fileName: file.name,
						fileType: file.type,
						fileData: convertedFile,
						type: 'Remito',
						user,
						relatedFiles: [newDeliveryData?.id],
						listId: baseInfo?.metadata?.list_id,
						listMigrationId: baseInfo?.metadata?.list_migration_id,
						orderMigrationId: baseInfo?.quotation?.order_migration_id,
						listEnvironment: baseInfo?.metadata?.env_list,
						filesFolderId: baseInfo?.metadata?.files_folder_id,
						documentId: (id_doc === undefined || id_doc === '') ? (Math.random() * 12000).toFixed(0) : id_doc,
						deliveryId: newDeliveryData?.id,
						redeemRequestId: baseInfo?.metadata?.redeem_request_id,
					});
					uploadedFiles.push(uploadedFile);
				}
			}
		}
	
		if (modalType === 'new') {
			fetchCreateShipping(uploadedFiles);
		} else {
			fetchUpdateShipping(uploadedFiles);
		}
	};

	const getTitle = (type: string) => {
		switch (type) {
		case 'new':
			return 'mytokens.redeem_view.deliveries.seller_delivery_modal.title.new';
		case 'edit':
			return 'mytokens.redeem_view.deliveries.seller_delivery_modal.title.edit';
		case 'view':
		default:
			return 'mytokens.redeem_view.deliveries.seller_delivery_modal.title.view';
		}
	}

	useEffect(() => {
		if (modalType === 'edit') {
			if (!delivery.files?.length) {
				setNewDeliveryData({ ...delivery, files: [{ file: undefined, id_doc: '', updated: false }] });
			} else {
				const files = delivery.files.map((file) =>{
					const blob = new Blob();
					return { file: new File([blob], `Remito_${file.id_doc}`), id_doc: file.id_doc, updated: false, id: file.id };
				});
				setNewDeliveryData({ ...delivery, files });
			}
		} else {
			setNewDeliveryData(delivery)
		}
	}, [delivery, modalType]);

	return (
		<SideModalContainer
			isOpen={open}
			onClose={onClose}
			sx={{ width: '480px', px: 3 }}
			onClick={(e) => {
				if (e.target instanceof HTMLElement) {
					if(!['checkbox', 'checkbox-label', 'datepicker'].includes(e.target.id)) {
						e.preventDefault()
					}
				}
				e.stopPropagation()
			}}
			showCloseIcon={false}
			slideDirection={isMobile ? 'up' : 'left'}
			modalPosition={isMobile ? 'center' : 'right'}
			modalContentAlign={isMobile ? 'bottom' : undefined}
		>
			{loading && <FullScreenLoading />}
			<DialogTitle>
				{newDeliveryData && (
					<Box className="flex justify-between items-start mt-[-14px]">
						<Box className="flex grow flex-col align-start justify-start items-start gap-1.5">
							<Box className="flex w-full items-center gap-1">
								<Typography variant="h6" mr={1} >{intl.formatMessage({ id: getTitle(modalType ?? '') })}</Typography>
								{modalType !== 'new' ? (
										newDeliveryData?.status === 1 ? (
											<PendingDeliveryStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][newDeliveryData?.status] })}</PendingDeliveryStatus>
										) : newDeliveryData?.status === 2 ? (
											<DeliveredStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][newDeliveryData?.status] })}</DeliveredStatus>
										) : (
											<CanceledDeliveryStatus>{intl.formatMessage({ id: DeliveryStatus[user.type_external][newDeliveryData?.status] })}</CanceledDeliveryStatus>
										) 
									) : (
										null
									)
								}
							</Box>
							{
								modalType !== 'new' && newDeliveryData?.id &&
								<Box className="flex items-center gap-1">
									<Typography variant="caption" className="font-light">ID:</Typography>
									<Typography variant="caption" className="text-[.62em] font-medium">{newDeliveryData?.id}</Typography>
								</Box>
							}
						</Box>
						<IconButton aria-label="close" onClick={onClose} className={modalType !== 'new' ? 'mt-[-4px]' : 'mt-[-6px]'}>
							<CloseIcon />
						</IconButton>
					</Box>
				)}
			</DialogTitle>
			<DialogContent>
				<DeliveryProducts
					redeemStep={redeemStep}
					unity={unity}
					delivery={delivery}
					modalType={modalType}
					setError={error => setState({ ...state, error })}
					redeemRequestProducts={redeemRequestProducts}
					newDeliveryData={newDeliveryData}
					setNewDeliveryData={setNewDeliveryData}
				/>
				<Box>
					{/* ESTIMATED DELIVERY DATE */}
					<InputLabel className='font-black flex mt-5 mb-2'>
						{intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.estimated_delivery_date' })}
					</InputLabel>
					<DatePicker
						identifier="estimated-delivery-date"
						disabled={modalType === 'view' || (newDeliveryData?.status === 2 && modalType === 'edit')}
						value={newDeliveryData?.estimated_delivery_date ? new Date(newDeliveryData?.estimated_delivery_date) : undefined}
						error={error === intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.error.estimated_delivery_date' })}
						onChange={(value) => {
							setState({ ...state, error: '' });
							setNewDeliveryData({ ...newDeliveryData, estimated_delivery_date: value?.toISOString() ?? '' });
						}}
					/>
				</Box>
				{modalType === 'new' && (
					<>
						<Box className='my-2'>
							<FormControlLabel
								control={
									<Checkbox
										checked={newDeliveryData?.status === 2}
										onChange={(e) => setNewDeliveryData({ ...newDeliveryData, status: e.target.checked ? 2 : 1 })}
									/>
								}
								label={intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.status_completed' })}
							/>
						</Box>
						{newDeliveryData?.status === 2 && <Divider orientation='horizontal' flexItem />}
					</>
				)}
				<DateFilesComments
					delivery={delivery}
					modalType={modalType}
					error={error}
					setError={error => setState({ ...state, error })}
					newDeliveryData={newDeliveryData}
					setNewDeliveryData={setNewDeliveryData}
					addFileToDelete={(file: any) => setState({ ...state, deletedFiles: [...deletedFiles, file] })}
				/>
				<Divider orientation='horizontal' flexItem className='my-2' />
				{Boolean(error) && <ErrorBox>{error}</ErrorBox>}
			</DialogContent>
			<DialogActions className='justify-center gap-1'>
				{modalType === 'view' ? (
					newDeliveryData?.status === 1 ? (
						<Stack
							gap={2}
							className='w-full flex felx-col items-center'
						>
							<Button
								onClick={() => setDeliveryModal?.({ data: {...newDeliveryData, status: 2 }, modalType: 'edit' })}
								sx={{ width: '76%' }}
								variant="contained"
							>
								{intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.mark_as_completed' })}
							</Button>
							<Button
								onClick={() => setDeliveryModal?.({ data: newDeliveryData, modalType: 'edit' })}
								sx={{ width: '76%' }}
								variant='outlined'
							>
								{intl.formatMessage({ id: 'mytokens.redeem_view.deliveries.seller_delivery_modal.edit_delivery' })}
							</Button>
						</Stack>
					) : (
						<Button
							disabled={!!loading}
							onClick={() => onClose(null, 'cancelButtonClick')}
							sx={{ width: '38%' }}
							variant="outlined"
						>
							{intl.formatMessage({ id: 'common.close' })}
						</Button>
					)	
				) : (
					<>
						<Button
							disabled={!!loading}
							onClick={() => onClose(null, 'cancelButtonClick')}
							sx={{ width: '38%' }}
							variant="outlined"
						>
							{intl.formatMessage({ id: 'common.cancel' })}
						</Button>
						<Button
							disabled={
								Boolean(error)
								|| !!loading
								|| (newDeliveryData?.status === 2 && !newDeliveryData?.delivery_date)
							}
							onClick={handleSubmit}
							sx={{ width: '38%' }}
							variant="contained"
						>
							{intl.formatMessage({ id: modalType === 'new' ? 'common.create' : 'common.confirm'})}
						</Button>
					</>
				)}
			</DialogActions>
		</SideModalContainer>
	);
};

export default SellerDeliveryModal;

