import { Grid, Stack, Typography } from '@mui/material';
import PageContainer from '../../components/page-container';
import { useIntl } from 'react-intl';
import Banner from './components/Banner';
import ItemList from './components/item-list';
import HomeClient from '../../clients/HomeClient';
import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import BasePageFrame from '../../components/base-components/base-page-frame';
import { useMobile } from '../../hooks/use-mobile';
import CrispCTAButton from '../../components/crisp-cta-button';

// This will be dynamic in the future, maybe fetch from an API
const homeData = HomeClient.getHomeData();

const HomePage = () => {
	const intl = useIntl();
	const isMobile = useMobile();
	const user = useRecoilValue(userState);

	return (
		<BasePageFrame
			transparent
			title={`${intl.formatMessage({ id: 'header.welcome_label' })}, ${user.name}`}
			endAdornment={<CrispCTAButton />}
		>
			<Stack spacing={isMobile ? 2 : 3} className="mt-5 2xl:mt-5 lg:mt-1">
				<Banner {...homeData.banner} user={user} />
				<ItemList items={homeData.items} user={user} />
			</Stack>
		</BasePageFrame>
	);
};

export default HomePage;
