import { useState } from 'react';
import styled from '@emotion/styled';
import { MoreVert } from '@mui/icons-material';
import { Card as MaterialCard, CardHeader, Typography, Box, LinearProgress, Alert, Button, CardContent, IconButton, Menu, MenuItem, CardProps, Stack, } from '@mui/material';
import { useIntl } from 'react-intl';
import { useMobile } from '../hooks/use-mobile';

const RoundedCard = styled(MaterialCard)`
	
	height: 100%;
	position: relative;
	display: flex;
	flex-flow: column;
`;

const RoundedCardHeader = styled(CardHeader)`
	padding: 32px;
	flex: 0 1 auto;
`;

const ErrorAlert = styled(Alert)`
	position: relative;
	top: 0;
	z-index: 1001;
	width: calc(100% + 22px);
	left: -11px;
`;

const RoundedCardContent = styled(CardContent)((props: { size?: string }) => ({
	flex: '1 1 auto',
	paddingTop: '0px',
	paddingBottom: '24px',
	paddingLeft: useMobile() ? '16px' : '32px',
	paddingRight: useMobile() ? '16px' : '32px',
}));

const RoundedCardFooter = styled('div')`
	width: calc(100% + 20px);
	position: absolute;
	background-color: black;
	bottom: 0;
	left: 0;
	color: #fff;
	padding: 10px;
	flex: 0 1 40px;
`;

export interface Action {
	displayName: string;
	icon?: React.ReactElement;
	disabled?: boolean;
	hidden?: boolean;
	type?: string | 'IconButton';
	variant?: 'contained' | 'outlined' | 'text';
	badge?: boolean;
	onClick: () => void;
}

interface Props extends CardProps {
	title?: string;
	titleLocation?: 'start' | 'center' | 'end';
	orderStatus?: string;
	subtitle?: React.ReactElement | string;
	actions?: Action[];
	avatar?: React.ReactElement | boolean;
	children?: React.ReactElement;
	footer?: React.ReactElement | boolean | string;
	loading?: boolean;
	error?: boolean;
	borderRadius?: number;
	onRetry?: () => void;
}

const Card = (props: Props) => {
	const {
		title,
		titleLocation,
		orderStatus,
		subtitle,
		actions,
		avatar,
		children,
		footer,
		loading,
		error,
		borderRadius,
		onRetry,
		...rest
	} = props;

	const intl = useIntl();
	const isMobile = useMobile();

	const [actionsAnchorEl, setActionsAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(actionsAnchorEl);

	const openActions = (event: React.MouseEvent<HTMLButtonElement>) => {
		setActionsAnchorEl(event.currentTarget);
	};
	const closeActions = () => {
		setActionsAnchorEl(null);
	};

	const shownActions = actions?.filter((action) => !action.hidden);
	const hiddenActions = actions?.filter((action) => action.hidden);

	const getActions = (
		<Stack spacing={1} direction="row" pt={isMobile ? 0 : 2} pb={isMobile ? 4 : 0} justifyContent={'center'}>
			{shownActions?.map((action, index) =>
				action.type === 'IconButton' ? (
					<IconButton key={`action-${index}`} aria-label={action.displayName} onClick={action.onClick}>
						{action.icon}
					</IconButton>
				) : (
					<Button
						key={`action-${index}`}
						onClick={action.onClick}
						variant={action.variant ?? 'contained'}
						sx={{ marginTop: '16px' }}
					>
						{action.displayName}
					</Button>
				)
			)}
			{hiddenActions && hiddenActions.length > 0 && (
				<>
					<IconButton aria-label="actions" onClick={openActions}>
						<MoreVert />
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={actionsAnchorEl}
						container={document.body}
						open={open}
						onClose={closeActions}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						{hiddenActions.map((action) => (
							<MenuItem disabled={action.disabled} onClick={action.onClick} key={action.displayName}>
								{action.displayName}
							</MenuItem>
						))}
					</Menu>
				</>
			)}
		</Stack>
	);

	return (
		<RoundedCard style={{ paddingBottom: footer ? 42 : 0 }} sx={{ borderRadius: borderRadius || '30px' }} {...rest}>
			{loading && (
				<Box className="w-full">
					<LinearProgress color="inherit" />
				</Box>
			)}
			{error && (
				<ErrorAlert
					severity="error"
					action={
						<Button color="inherit" size="small" onClick={onRetry}>
							{intl.formatMessage({ id: 'common.retry.button' })}
						</Button>
					}
				>
					{intl.formatMessage({ id: 'common.loading_error_message.details' })}
				</ErrorAlert>
			)}
			{(title || subtitle || avatar) && (
				<RoundedCardHeader
					avatar={avatar}
					title={
						title && (
							<Typography variant="h5" textAlign={titleLocation}>
								{title}
								{orderStatus && (
									<>
										<span> - </span>
										<span style={{ color: 'rgb(23, 55, 183)' }}>{orderStatus}</span>
									</>
								)}
							</Typography>
						)
					}
					subheader={subtitle}
					action={!isMobile && getActions}
					sx={{ minHeight: '130px' }}
				/>
			)}

			<RoundedCardContent>
				{isMobile && getActions}
				{children}
			</RoundedCardContent>
			{footer && <RoundedCardFooter>{footer}</RoundedCardFooter>}
		</RoundedCard>
	);
};

export default Card;
