import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import PageContainer from '../page-container';
import { KeyboardBackspace } from '@mui/icons-material';
import { useIntl } from 'react-intl';

interface basePageFrameProps {
	title: string | ReactNode;
	subtitle?: string;
	image?: ReactNode;
	children: ReactNode;
	endAdornment?: ReactNode;
	transparent?: boolean;
	titleContainerStyles?: string;
	titleStyles?: string;
	childContainerStyles?: string;
	returnAction?: () => void;
	returnActionTitle?: string;
}

const BasePageFrame: React.FC<basePageFrameProps> = ({
	title,
	subtitle,
	children,
	image,
	endAdornment,
	transparent,
	titleContainerStyles,
	titleStyles,
	childContainerStyles,
	returnAction,
	returnActionTitle,
}) => {
	const intl = useIntl();
	return (
		<PageContainer>
			<Grid container>
				{returnAction && (
					<Grid item xs={12}>
						<Button onClick={returnAction} startIcon={<KeyboardBackspace />}>
							{returnActionTitle ?? intl.formatMessage({ id: 'common.back' })}
						</Button>
					</Grid>
				)}

				<Grid item xs={12}>
					<Stack direction={'row'} width={'100%'}>
						{image && (
							<Box className='max-w-[4.68rem] max-h-[4.68rem] pr-1'>
								{image}
							</Box>
						)}
						<Stack direction={'column'} width={'100%'}>
							<Box className={titleContainerStyles || 'justify-between items-baseline flex'}>
								<Typography className={titleStyles} variant="h2" gutterBottom>
									{title}
								</Typography>
								{endAdornment}
							</Box>
							{ subtitle && <Typography variant="h5">{subtitle}</Typography>}
						</Stack>
					</Stack>
				</Grid>
				<Grid className={'mt-2 2xl:mt-5'} item xs={12}>
					<Paper
						className={`${transparent ?? 'p-[2.2rem]'} w-full rounded-md shadow-none ${transparent && 'bg-transparent'
							}` + (childContainerStyles ? ` ${childContainerStyles}` : '')}
					>
						{children}
					</Paper>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default BasePageFrame;
