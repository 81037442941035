import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ConstructionAddress, FileWithContent, Project, Trilean, User } from '../../types';
import {
	Alert,
	Autocomplete,
	Button,
	Card,
	CardContent,
	CardHeader,
	Container,
	FormControl,
	FormControlLabel,
	Grid,
	Paper,
	InputLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	Box,
	debounce,
	styled,
	Snackbar,
} from '@mui/material';
import FileUpload from 'react-material-file-upload';
import DatePicker from '../date-picker';
import { FileError, ErrorCode } from 'react-dropzone';
import client from '../../clients/client';
import filesClient from '../../clients/filesClient';
import quotationsClient from '../../clients/quotationsClient';
import { randomizeFileNames } from '../../helpers/filesHelper';
import ProjectService from '../projects/projectsService';

import { useNavigate } from 'react-router-dom';
import FullScreenLoading from '../full-screen-loading';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import exampleQuotation from '../../assets/example-quotation.png';
import InputContainer from '../my-tokens/dashboard/InputContainer';
import SideModalContainer from '../modals/side-modal-container';
import AddressForm from '../address-form';
import CrispCTAButton from '../crisp-cta-button';
import { useMobile } from '../../hooks/use-mobile';
import { useSetRecoilState } from 'recoil';
import disableRootElementScrollState from '../../atoms/disable-root-element-scroll-state';
import InfoButton from '../info-button';
import PageContainer from '../page-container';
import BasePageFrame from '../base-components/base-page-frame';

const Label = styled(InputLabel)`
	margin-top: 15px;
	margin-bottom: 5px;
	font-weight: 900;
`;

const Details = styled(Typography)`
	margin-bottom: 5px;
`;

const Restrictions = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
}));

const FileUploadWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	'& > *': {
		border: `2px dashed  ${theme.palette.divider} !important`,
		backgroundColor: theme.palette.background.default,
	},
	'& .MuiFormControl-root': {
		flexDirection: 'row',
		alignItems: 'center',
		gap: 8,
		'& .MuiTypography-root': {
			fontSize: '1em',
			paddingTop: 20,
			paddingBottom: 20,
		},
		'& .MuiSvgIcon-root': {
			fill: '#0d6efd',
			fontSize: 30,
			marginBottom: 4,
			marginLeft: 30,
		},
		'& .MuiButtonBase-root': {
			position: 'absolute',
			background: 'transparent',
			color: 'transparent',
			width: '100%',
			height: '10vh',
			marginBottom: -8,
			marginLeft: 0,
		},
	},
}));

const TextFieldForAutocomplete = styled(TextField)(({ error }) => ({
	'& .MuiOutlinedInput-root': {
		border: error ? '1px solid #FF3D00' : 'inherit',
		color: error ? '#FF3D00' : 'inherit',
	},
}));

interface Props {
	user: User;
	toggleEmptyState: (showEmptyState: boolean) => void;
}

interface ProjectOption {
	id: string;
	label: string;
}

interface ProjectOptions {
	[x: string]: Project;
}

const shippingOptions: { [x: string]: { label: string; clientValue: string } } = {
	[Trilean.TRUE]: { label: 'new_quotation.shipping.yes', clientValue: 'SI' },
	[Trilean.FALSE]: { label: 'new_quotation.shipping.no', clientValue: 'NO' },
	[Trilean.UNKNOWN]: { label: 'new_quotation.shipping.unknown', clientValue: 'SIN ESPECIFICAR' },
};

const financingOptions: { [x: string]: { label: string; clientValue: string } } = {
	[Trilean.TRUE]: { label: 'new_quotation.financing.yes', clientValue: 'SI' },
	[Trilean.FALSE]: { label: 'new_quotation.financing.no', clientValue: 'NO' },
	[Trilean.UNKNOWN]: { label: 'new_quotation.financing.unknown', clientValue: 'SIN ESPECIFICAR' },
};

const filesMaxSize = 10;
const filesAllowedTypes = [
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
	'image/bmp',
	'image/png',
	'image/jpeg',
	'image/svg+xml',
	'image/webp',
];

const NewQuotation = (props: Props) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const isMobile: boolean = useMobile();
	const setDisableRootElementScroll = useSetRecoilState(disableRootElementScrollState);
	const { user, toggleEmptyState } = props;

	const [project, setProject] = useState<Project>();
	const [date, setDate] = useState<Date>();
	const [shipping, setShipping] = useState<Trilean>(Trilean.TRUE);
	const [financing, setFinancing] = useState<Trilean>(Trilean.UNKNOWN);
	const [requestedQuotationFiles, setRequestedQuotationFiles] = useState<File[]>([]);
	const [referenceQuotationFiles, setReferenceQuotationFiles] = useState<File[]>([]);
	const [deliveryScheduleFiles, setDeliveryScheduleFiles] = useState<File[]>([]);
	const [quotationPlanFiles, setQuotationPlanFiles] = useState<File[]>([]);
	const [comments, setComments] = useState<string>();

	const [projectOptions, setProjectOptions] = useState<ProjectOptions>();

	const [loading, setLoading] = useState<boolean>();
	const [error, setError] = useState<string>();
	const [fieldsWithError, setFieldsWithError] = useState<Set<string>>(new Set());

	const [showSideModal, setShowSideModal] = useState(false);
	const [addresses, setAddresses] = useState<{ key: string; label: string }[]>([]);
	const [address, setAddress] = useState<{ key: string; label: string } | null>(null);

	const [loadingAddress, setLoadingAddress] = useState(false);

	const handleProjectChange = (projectOption: ProjectOption | null) => {
		if (!projectOption || !projectOptions) {
			setProject({
				id_proyecto: 'sin-proyecto',
				id_constructora: user.id_external,
				nombre_proyecto: '-- Sin proyecto asociado --',
				direccion: null,
			});
			return;
		}

		const project = projectOptions[projectOption.id];
		setProject(project);

		const projectAddressKey = project.direccion;
		const addressesMap = new Map(addresses.map((address) => [address.key, address]));
		setAddress(projectAddressKey ? addressesMap.get(projectAddressKey) ?? null : null);
	};

	const validateFilesLoad = (file: File): FileError | null => {
		if (!filesAllowedTypes.includes(file.type)) {
			return {
				message: intl.formatMessage({ id: 'new_quotation.error_message.invalid_file_type' }),
				code: ErrorCode.FileInvalidType,
			};
		}

		if (file.size / 1024 / 1024 >= filesMaxSize) {
			return {
				message: intl.formatMessage(
					{ id: 'new_quotation.error_message.invalid_file_size' },
					{ maxFileSize: filesMaxSize }
				),
				code: ErrorCode.FileTooLarge,
			};
		}

		return null;
	};

	const uploadFiles = async () => {
		try {
			const renamedRequestedQuotationFiles = randomizeFileNames([...requestedQuotationFiles]);
			const renamedReferenceQuotationFiles = randomizeFileNames([...referenceQuotationFiles]);
			const renamedDeliveryScheduleFiles = randomizeFileNames([...deliveryScheduleFiles]);
			const renamedQuotationPlanFiles = randomizeFileNames([...quotationPlanFiles]);

			const preparedFiles = await quotationsClient.prepareUpload(
				user,
				renamedRequestedQuotationFiles,
				renamedReferenceQuotationFiles,
				renamedDeliveryScheduleFiles,
				renamedQuotationPlanFiles
			);

			const allFiles = flattenFiles(preparedFiles.files);

			const originalFiles = [
				...renamedRequestedQuotationFiles,
				...renamedReferenceQuotationFiles,
				...renamedDeliveryScheduleFiles,
				...renamedQuotationPlanFiles,
			];

			const uploadPromises = originalFiles.flatMap((file) => {
				const fileIndex = allFiles.findIndex((f) => f.name === file?.name);
				if (fileIndex >= 0) {
					const fileUrl = allFiles[fileIndex].upload_url;
					return filesClient.uploadFileWithUrl(fileUrl, file);
				}
				return [];
			});

			const responses = await Promise.all(uploadPromises);

			if (responses.some((response) => !response.success)) {
				throw new Error('Error uploading files');
			}

			return preparedFiles;
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	const flattenFiles = (files: { [key: string]: { files: FileWithContent[] } }): any[] => {
		const flattenedFiles: FileWithContent[] = [];
		Object.values(files).forEach((fileGroup) => {
			flattenedFiles.push(...fileGroup.files);
		});

		return flattenedFiles;
	};

	const validate = () => {
		const newFieldsWithError = new Set<string>();

		if (!date) {
			newFieldsWithError.add('date');
		}

		if (requestedQuotationFiles.length === 0 && !comments) {
			newFieldsWithError.add('requestedQuotationFiles|comments');
		}

		if (!project) {
			newFieldsWithError.add('project');
		}

		if (shipping === Trilean.TRUE && !address?.key) {
			newFieldsWithError.add('address');
		}

		return newFieldsWithError;
	};

	const handleSubmit = async () => {
		const newFieldsWithError = validate();

		if (newFieldsWithError.size > 0) {
			setError(intl.formatMessage({ id: 'new_quotation.error_message.missing_required_fields' }));
			setFieldsWithError(newFieldsWithError);
			return;
		}

		setLoading(true);

		const uploadedFiles = await uploadFiles();

		if (!uploadedFiles || uploadedFiles.success === false) {
			setLoading(false);
			setError(intl.formatMessage({ id: 'common.upload_files_error' }));
			return;
		}

		const submitResults = await quotationsClient.submitQuotation({
			project: project,
			date,
			shipping: shippingOptions[shipping]?.clientValue,
			address: address?.key,
			financing: financingOptions[financing]?.clientValue,
			comments,
			files: uploadedFiles.files,
			listId: uploadedFiles.list_id,
			user,
		});

		if (!submitResults || submitResults.error) {
			console.error(submitResults.message);
			setLoading(false);
			setError(intl.formatMessage({ id: 'new_quotation.error_message.failed_submit' }));
			return;
		}

		setLoading(false);
		navigate(`/new-quotation-confirmed?listId=${submitResults.id_lista}`);
	};

	const fetchData = debounce(() => {
		setLoading(true);
		setProjectOptions(undefined);

		ProjectService.allProjects(user).then((data) => {
			// const projects: any[] = projectData.projects?.data.map((project: any) => ({
			// 	id: project.id,
			// 	name: project.name,
			// 	address: project.full_address
			// 		? addressHelper.formatAddress({
			// 				address: project.full_address.address,
			// 				city: project.full_address.city,
			// 				province: project.full_address.province,
			// 			})
			// 		: '',
			// 	type: project.type ? project.type : '',
			// })) || [];

			// setProjects(projects);

			// client.listProjects({ user }).then((data) => {
			// (projectData.projects as any[])?.map((project: any) => {
			const results = data?.projects || ([] as any[]);
			const projectOptions: ProjectOptions = {};
			const filteredProjects = results.filter((result: any) => result.state === 1);

			for (let result of filteredProjects) {
				projectOptions[String(result.id)] = {
					id_proyecto: String(result.id),
					id_constructora: result.propietary,
					nombre_proyecto: result.name,
					direccion: result.address,
				};
			}

			projectOptions['sin-proyecto'] = {
				id_proyecto: 'sin-proyecto',
				id_constructora: user.id_external,
				nombre_proyecto: '-- Sin proyecto asociado --',
				direccion: null,
			};

			setProjectOptions(projectOptions);
			setProject({
				id_proyecto: 'sin-proyecto',
				id_constructora: user.id_external,
				nombre_proyecto: '-- Sin proyecto asociado --',
				direccion: null,
			});

			client.getOrganizationAddresses({ token: user.token, id: user.id_external }).then((response) => {
				setLoading(false);
				setAddresses(
					response.data.map((item: any) => ({ key: item.id, label: `${item.address}, ${item.city}, ${item.province}` }))
				);
			});
		});
	}, 250);

	const handleCreateAddress = async (address: ConstructionAddress) => {
		if (address) {
			address.organizationId = user.id_external;
		}

		const saveAddress = async () => {
			try {
				setLoadingAddress(true);
				await client.createOrganizationAddress({ data: address, token: user.token });

				const constrAddresses = await client.getOrganizationAddresses({ token: user.token, id: user.id_external });
				const additionalAddresses = constrAddresses.data.map((item: any) => ({
					key: item.id,
					label: `${item.address}, ${item.city}, ${item.province}`,
				}));

				setAddress(additionalAddresses[0] || null);
				setAddresses(additionalAddresses);

				setLoadingAddress(false);
			} catch (error) {
				console.error(error);
			}
		};

		await saveAddress();
		setShowSideModal(false);
	};

	const handleClearFieldsWithError = (fieldToDelete: string) => {
		if (!fieldsWithError.has(fieldToDelete)) {
			return;
		}

		const newFieldsWithError = new Set<string>();
		fieldsWithError.forEach((field) => field !== fieldToDelete && newFieldsWithError.add(field));
		setFieldsWithError(newFieldsWithError);
	};

	useEffect(() => fetchData(), []);

	useEffect(() => {
		setDisableRootElementScroll(showSideModal);
	}, [setDisableRootElementScroll, showSideModal]);

	const buttons = (
		<Box className="flex items-center gap-4">
			<CrispCTAButton />
			<InfoButton text={intl.formatMessage({ id: 'common.more_information' })} onClick={() => toggleEmptyState(true)} />
		</Box>
	);

	return (
		<BasePageFrame title={intl.formatMessage({ id: 'new_quotation.title' })} endAdornment={buttons}>
			<SideModalContainer
				sx={{ width: '430px' }}
				slideDirection={isMobile ? 'up' : 'left'}
				modalPosition={isMobile ? 'center' : 'right'}
				modalContentAlign={isMobile ? 'bottom' : undefined}
				isOpen={showSideModal}
				loading={loadingAddress}
				onClose={() => setShowSideModal(false)}
			>
				<AddressForm
					columnLayout
					retrieveAddress={handleCreateAddress}
					onCancel={() => setShowSideModal(false)}
				></AddressForm>
			</SideModalContainer>

			<Grid sx={{ ml: '5px' }} container spacing={2}>
				<Paper
					elevation={0}
					sx={{
						marginTop: '15px',
						width: '100%',
						borderRadius: 2,
						height: '100%',
					}}
				>
					{loading && <FullScreenLoading />}
					<Card raised={false} elevation={0}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Label sx={fieldsWithError.has('project') ? { color: '#FF3D00' } : {}}>
										{intl.formatMessage({ id: 'new_quotation.project_field_name' })}
									</Label>
									<Autocomplete
										disablePortal
										size="small"
										fullWidth
										options={Object.values(projectOptions || {}).map((option) => ({
											id: option.id_proyecto,
											label: option.nombre_proyecto,
										}))}
										renderInput={(params) => (
											<TextFieldForAutocomplete
												{...params}
												placeholder={intl.formatMessage({
													id: 'new_quotation.project_field_placeholder',
												})}
												error={fieldsWithError.has('project')}
												onMouseDown={() => handleClearFieldsWithError('project')}
											/>
										)}
										value={project ? { id: project.id_proyecto, label: project.nombre_proyecto } : null}
										onChange={(event, newValue) => handleProjectChange(newValue)}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Label sx={fieldsWithError.has('date') ? { color: '#FF3D00' } : {}}>
										{intl.formatMessage({ id: 'new_quotation.date_field_name' })}
									</Label>
									<DatePicker
										value={date}
										onChange={(value) => setDate(value)}
										onClick={() => handleClearFieldsWithError('date')}
										variant="form"
										min={new Date()}
										error={fieldsWithError.has('date')}
									/>
								</Grid>
								<Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
									<Box>
										<Label>{intl.formatMessage({ id: 'new_quotation.shipping_field_name' })}</Label>
										<FormControl required>
											<RadioGroup
												value={shipping}
												onChange={(event: any) => setShipping(event.target.value as Trilean)}
												row
												sx={{
													'& .MuiButtonBase-root.Mui-checked': {
														color: '#000',
														'& .MuiSvgIcon-root:nth-child(2)': {
															fill: '#3B74FB',
														},
													},
												}}
											>
												{Object.keys(shippingOptions).map((option) => (
													<FormControlLabel
														key={option}
														value={option}
														control={<Radio />}
														label={intl.formatMessage({ id: shippingOptions[option].label })}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</Box>
									{shipping !== Trilean.FALSE && (
										<Box>
											<Label>{intl.formatMessage({ id: 'new_quotation.financing_field_name' })}</Label>
											<FormControl required>
												<RadioGroup
													value={financing}
													onChange={(event: any) => setFinancing(event.target.value as Trilean)}
													row
													sx={{
														'& .MuiButtonBase-root.Mui-checked': {
															color: '#000',
															'& .MuiSvgIcon-root:nth-child(2)': {
																fill: '#3B74FB',
															},
														},
													}}
												>
													{Object.keys(financingOptions).map((option) => (
														<FormControlLabel
															key={option}
															value={option}
															control={<Radio />}
															label={intl.formatMessage({ id: financingOptions[option].label })}
														/>
													))}
												</RadioGroup>
											</FormControl>
										</Box>
									)}
								</Grid>
								{shipping !== Trilean.FALSE && (
									<Grid item xs={12} md={6}>
										<InputContainer
											error={fieldsWithError.has('address')}
											title={intl.formatMessage({ id: 'new_quotation.location_field_name' })}
										>
											<Autocomplete
												disablePortal
												size="small"
												fullWidth
												options={[
													...Object.values(addresses || {}).map((option) => ({
														key: option.key,
														id: option.key,
														label: option.label,
													})),
													...[{ id: 'newAddress', label: 'Nueva Dirección' }],
												]}
												renderInput={(params) => (
													<TextFieldForAutocomplete
														{...params}
														placeholder={intl.formatMessage({
															id: 'new_quotation.address_field_placeholder',
														})}
														error={fieldsWithError.has('address')}
														// helperText={fieldsWithError.has('address') ? error : ''}
														onMouseDown={() => handleClearFieldsWithError('address')}
													/>
												)}
												value={address ? { id: address.key, label: address.label } : null}
												onChange={(event, newValue) => {
													if (newValue?.id === 'newAddress') {
														setShowSideModal(true);
														return;
													}
													return newValue ? setAddress({ key: newValue?.id, label: newValue?.label }) : null;
												}}
											/>
										</InputContainer>
									</Grid>
								)}

								{shipping === Trilean.FALSE && (
									<Grid item xs={12} md={6}>
										<Label>{intl.formatMessage({ id: 'new_quotation.financing_field_name' })}</Label>
										<FormControl required>
											<RadioGroup
												value={financing}
												onChange={(event: any) => setFinancing(event.target.value as Trilean)}
												row
												sx={{
													'& .MuiButtonBase-root.Mui-checked': {
														color: '#000',
														'& .MuiSvgIcon-root:nth-child(2)': {
															fill: '#3B74FB',
														},
													},
												}}
											>
												{Object.keys(financingOptions).map((option) => (
													<FormControlLabel
														key={option}
														value={option}
														control={<Radio />}
														label={intl.formatMessage({ id: financingOptions[option].label })}
													/>
												))}
											</RadioGroup>
										</FormControl>
									</Grid>
								)}
								<Grid container>
									<Box sx={{ px: 2 }}>
										<Label sx={fieldsWithError.has('requestedQuotationFiles|comments') ? { color: '#FF3D00' } : {}}>
											{intl.formatMessage({ id: 'new_quotation.requested_quotation_file_field_name' })}
										</Label>
										<Details variant="body2">
											{intl.formatMessage(
												{ id: 'new_quotation.requested_quotation_file_field_details' },
												{
													exampleLink: (
														<a
															href={exampleQuotation}
															download={`${intl.formatMessage({
																id: 'new_quotation.example_quotation.file_name',
															})}.xlsx`}
														>
															{intl.formatMessage({ id: 'new_quotation.example_quotation.file_name' })}
														</a>
													),
												}
											)}
										</Details>
									</Box>
									<Box
										sx={{
											display: 'flex',
											flexDirection: {
												md: 'row',
												xs: 'column',
											},
											alignItems: 'center',
											width: '100%',
										}}
									>
										<Grid
											item
											xs={12}
											md={6}
											sx={{ pr: 1, pl: { md: 2.5, xs: 1 }, width: '100%' }}
											onClick={() => handleClearFieldsWithError('requestedQuotationFiles|comments')}
										>
											<FileUploadWrapper>
												<FileUpload
													value={requestedQuotationFiles}
													onChange={setRequestedQuotationFiles}
													validator={(file: File) => validateFilesLoad(file)}
													multiple={false}
													buttonText={intl.formatMessage({ id: 'common.upload' })}
													title={intl.formatMessage({
														id: 'new_quotation.requested_quotation_file_field_placeholder',
													})}
													sx={{
														borderRadius: 4,
														mt: 1,
														width: '100%',
														'& .MuiFormControl-root': {
															display: requestedQuotationFiles && requestedQuotationFiles.length ? 'none' : 'flex',
														},
													}}
												/>
											</FileUploadWrapper>
										</Grid>
										<Grid
											item
											xs={12}
											md={6}
											sx={{ pr: 1, pl: { md: 2.5, xs: 1 }, mt: 1, borderRadius: 12, width: '100%' }}
										>
											<TextField
												fullWidth
												multiline
												size="small"
												rows={5}
												value={comments}
												onChange={(event) => setComments(event.target.value)}
												onMouseDown={() => handleClearFieldsWithError('requestedQuotationFiles|comments')}
												placeholder={intl.formatMessage({
													id: 'new_quotation.requested_quotation_comments_placeholder',
												})}
												sx={{
													'& .MuiInputBase-root': {
														borderRadius: 4,
														border: '1.4px solid #ccc',
													},
													'& .MuiInputBase-root::before': {
														visibility: {
															md: 'visible',
															xs: 'hidden',
														},
														content: '"ó"',
														position: 'absolute',
														fontSize: '1.2em',
														ml: -4.2,
													},
													'& .MuiInputBase-input::placeholder': {
														position: 'absolute',
														top: '50%',
														left: '50%',
														transform: 'translate(-50%, -50%)',
													},
													mt: {
														md: 0,
														xs: 1,
													},
												}}
											/>
										</Grid>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<Label>{intl.formatMessage({ id: 'new_quotation.additional_info_file_field_name' })}</Label>
									<Details variant="body2">
										{intl.formatMessage({ id: 'new_quotation.additional_info_file_field_details' })}
									</Details>
									<Grid container spacing={2}>
										<Grid item xs={12} md={4}>
											<FileUploadWrapper>
												<FileUpload
													value={referenceQuotationFiles}
													onChange={setReferenceQuotationFiles}
													validator={(file: File) => validateFilesLoad(file)}
													multiple={false}
													buttonText={intl.formatMessage({ id: 'common.upload' })}
													title={intl.formatMessage({ id: 'new_quotation.reference_quotation_file_field_details' })}
													sx={{
														borderRadius: 4,
														mt: 1,
														'& .MuiFormControl-root': {
															display: referenceQuotationFiles && referenceQuotationFiles.length ? 'none' : 'flex',
														},
													}}
												/>
											</FileUploadWrapper>
										</Grid>
										<Grid item xs={12} md={4}>
											<FileUploadWrapper>
												<FileUpload
													value={deliveryScheduleFiles}
													onChange={setDeliveryScheduleFiles}
													validator={(file: File) => validateFilesLoad(file)}
													multiple={false}
													buttonText={intl.formatMessage({ id: 'common.upload' })}
													title={intl.formatMessage({ id: 'new_quotation.delivery_schedule_file_field_details' })}
													sx={{
														borderRadius: 4,
														mt: 1,
														'& .MuiFormControl-root': {
															display: deliveryScheduleFiles && deliveryScheduleFiles.length ? 'none' : 'flex',
														},
													}}
												/>
											</FileUploadWrapper>
										</Grid>
										<Grid item xs={12} md={4}>
											<FileUploadWrapper>
												<FileUpload
													value={quotationPlanFiles}
													onChange={setQuotationPlanFiles}
													validator={(file: File) => validateFilesLoad(file)}
													multiple={false}
													buttonText={intl.formatMessage({ id: 'common.upload' })}
													title={intl.formatMessage({ id: 'new_quotation.quotation_plan_file_field_details' })}
													sx={{
														borderRadius: 4,
														mt: 1,
														'& .MuiFormControl-root': {
															display: quotationPlanFiles && quotationPlanFiles.length ? 'none' : 'flex',
														},
													}}
												/>
											</FileUploadWrapper>
										</Grid>
									</Grid>
									<Restrictions variant="caption">
										{intl.formatMessage(
											{ id: 'new_quotation.additional_info_file_restrictions' },
											{ maxFileSize: filesMaxSize }
										)}
									</Restrictions>
								</Grid>
								<Grid item xs={12}>
									<Button onClick={handleSubmit} variant="contained" className="float-right">
										{intl.formatMessage({ id: 'common.send' })}
									</Button>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Paper>
			</Grid>
			<Snackbar
				open={!!error}
				onClose={() => setError('')}
				autoHideDuration={6000}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert severity="error" variant="filled" sx={{ marginLeft: { xs: '0px', md: '280px' }, width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>
		</BasePageFrame>
	);
};

export default NewQuotation;
