import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { StyledMyTokensDashboardDialog } from './tokens-seller';
import { Box, Dialog } from '@mui/material';

interface Props {
	open: boolean;
	onClose: () => void;
	type: 'pause' | 'reset';
	actionButton: () => void;
	date: string;
}

const HistoryTokensConfirmDialog = (props: Props) => {
	const { open, onClose, type, actionButton, date } = props;
	const intl = useIntl();

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			fullWidth
			maxWidth="md"
			sx={{
				'.MuiDialog-paper': {
					borderRadius: '8px',
				},
			}}
		>
			<DialogTitle
				sx={{
					fontSize: '18px',
					fontWeight: 700,
					padding: '0 0 14px 0',
					marginBottom: '14px',
					borderBottom: '1px solid #C6C6C6',
				}}
			>
				{intl.formatMessage({ id: `mytokens.seller.dashboard.dialog.history_tokens.confirmation.${type}.title` })}
			</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: '18px' }}>
				<Typography variant="inherit" sx={{ fontWeight: 300 }}>
					{intl.formatMessage(
						{ id: `mytokens.seller.dashboard.dialog.history_tokens.confirmation.${type}.legend1` },
						{ confirmationDate: date }
					)}
				</Typography>
				<Typography variant="inherit" sx={{ fontWeight: 300 }}>
					{intl.formatMessage({ id: `mytokens.seller.dashboard.dialog.history_tokens.confirmation.${type}.legend2` })}
				</Typography>
				{type === 'pause' && (
					<Box
						sx={{
							display: 'flex',
							alignSelf: 'center',
							marginTop: '1rem',
							backgroundColor: '#0047ff31',
							color: '#000000',
							borderRadius: '8px',
							fontWeight: 600,
							padding: '10px',
							width: '370px',
						}}
					>
						<Typography variant="inherit">
							{intl.formatMessage({
								id: `mytokens.seller.dashboard.dialog.history_tokens.confirmation.pause.disclaimer`,
							})}
						</Typography>
					</Box>
				)}
			</DialogContent>
			<DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
				<Button
					onClick={onClose}
					sx={{ 
						width: '162px',						
					}}
					variant="contained"
				>
					{intl.formatMessage({ id: 'common.cancel' })}
				</Button>
				<Button
					onClick={actionButton}
					sx={{ 
						width: '162px',						
					}}
					variant="contained"
				>
					{intl.formatMessage({ id: `mytokens.seller.dashboard.dialog.history_tokens.confirmation.${type}.cta` })}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default HistoryTokensConfirmDialog;
