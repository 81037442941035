import EmptyState from '../empty-state/empty-state';
import { FeatureNames, Features } from '../../types';
import NewQuotation from './new-quotation';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import EmptyStatesClient from '../../clients/EmptyStatesClient';
import BasePageFrame from '../base-components/base-page-frame';
import userState from '../../atoms/user-state';
import { useRecoilValue } from 'recoil';
import client from '../../clients/client';
import { debounce } from '@mui/material';
import CrispCTAButton from '../crisp-cta-button';

interface Props {
	checkPageVisualization: boolean;
}

const NewQuotationsEmptyState = (props: Props) => {
	const intl = useIntl();
	const user = useRecoilValue(userState);
	const pageViewed = EmptyStatesClient.pageHasAlreadyViewed(FeatureNames.NEW_QUOTATION, user);
	const [showEmptyState, setShowEmptyState] = useState<boolean>(!pageViewed);
	const [brands, setBrands] = useState<any[]>([]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchBrands = useCallback(
		debounce(() => {
			client
				.getBrandsLogos({ token: user.token })
				.then((response) => setBrands(response.data))
				.catch((error) => console.error(error));
		}, 250),
		[]
	);

	useEffect(() => {
		if (showEmptyState && user.token) {
			fetchBrands();
		}
	}, [fetchBrands, showEmptyState, user.token]);

	return showEmptyState ? (
		<BasePageFrame
			title={intl.formatMessage({ id: 'new_quotation.title' })}
			subtitle={intl.formatMessage({ id: 'new_quotation.subtitle' })}
			transparent
			endAdornment={<CrispCTAButton />}
		>
			<EmptyState
				pageIdentifier={Features.EMPTY_STATE_NEW_QUOTATION}
				banner={{
					title: '¿Cómo funciona el pedido de cotización?',
					cta: {
						label: 'Pedir cotización',
						onClick: () => setShowEmptyState(false),
					},
					video: 'https://www.youtube.com/embed/SY08XNtb5qA?si=acjpLwQ24oHRq2bS',
				}}
				brands={brands}
				categories={[
					'Hierro',
					'Adhesivos y pastinas',
					'Cables',
					'Ladrillos cerámicos',
					'Puertas',
					'Griferias y sanitarios',
					'Perfiles y chapas',
					'Pisos',
					'Aberturas',
				]}
			/>
		</BasePageFrame>
	) : (
		<NewQuotation toggleEmptyState={setShowEmptyState} user={user} />
	);
};

export default NewQuotationsEmptyState;
