import filesClient from '../clients/filesClient';
import { User } from '../types';

const requestUrlAndUploadFile = async (user: User, bucketName: string, file: File): Promise<any> => {
	try {
		const presignedUrlResponse = await filesClient.requestPresignedUrlUpload(user, bucketName, file.name, file.type);

		if (presignedUrlResponse.success) {
			const uploadResponse = await filesClient.uploadFileWithUrl(presignedUrlResponse.url, file);

			return uploadResponse;
		}

		return presignedUrlResponse;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

const randomizeFileNames = (files: File[]) => {
	return files.map((file) => {
		const randomValue = Math.random().toString(36).substring(2, 15);
		return new File([file], `${randomValue}-${file.name}`, { type: file.type });
	});
};

const downloadFile = (fileData: any, type?: string, id?: string, fullFileName?: string) => {
	const blob = new Blob([fileData], { type: 'application/octet-stream' });
	const blobURL = URL.createObjectURL(blob);
	const a = document.createElement('a');

	a.setAttribute('target', '_blank');
	a.setAttribute('download', type ? `${type}-${id}.${fullFileName?.split('.').pop()}` : fullFileName ?? 'file');
	a.setAttribute('href', blobURL);
	a.click();
};

export { requestUrlAndUploadFile, randomizeFileNames, downloadFile };
