import { styled, Box } from '@mui/material';
import React, { useEffect, useRef, useState, Children } from 'react';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';

const Placeholder = styled('div')((props: { height?: number | string; width?: number | string }) => ({
	width: props.width || '100%',
	height: props.height || '100%',
}));

interface Props {
	mini?: boolean | false;
	center: google.maps.LatLngLiteral;
	zoom: number;
	height?: number | string;
	width?: number | string;
	gestureHandling?: string;
	zoomControl?: boolean;
	fullscreenControl?: boolean;
	mapTypeControl?: boolean;
	streetViewControl?: boolean;
	children?: React.ReactNode;
	onClick?: (event: google.maps.MapMouseEvent) => void;
}

const Map = (props: Props) => {
	const ref = useRef<HTMLDivElement>(null);

	const { mini, height, width, children, onClick } = props;

	const [map, setMap] = useState<google.maps.Map>();

	useEffect(() => {
		if (ref.current && !map) {
			setMap(
				new window.google.maps.Map(
					ref.current,
					mini
						? {
								zoom: 15,
								mapTypeId: google.maps.MapTypeId.ROADMAP,
								disableDefaultUI: true,
								gestureHandling: 'none',
								styles: [
									{
										featureType: 'poi',
										stylers: [{ visibility: 'off' }],
									},
								],
								draggableCursor: 'pointer',
						  }
						: {
								zoom: 15,
								mapTypeId: google.maps.MapTypeId.ROADMAP,
								panControl: false,
								zoomControl: false,
								mapTypeControl: false,
								scaleControl: false,
								streetViewControl: false,
						  }
				)
			);
		} else if (map) {
			google.maps.event.clearListeners(map, 'click');

			if (onClick) {
				map.addListener('click', onClick);
			}
		}
	}, [ref, map]);

	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions({ ...props, center: { ...props.center, lat: props.center.lat + 0.00066 } });
		}
	}, [map, props]);

	return (
		<>
			{/* @ts-ignore */}
			<Box className="cursor-pointer">
				<Placeholder ref={ref} id="map" height={height} width={width} className={mini ? 'gmap-mini' : 'gmap'} />
				{Children.map(children, (child) => {
					if (React.isValidElement(child)) {
						// set the map prop on the child component
						// @ts-ignore
						return React.cloneElement(child, { map });
					}
				})}
			</Box>
		</>
	);
};

const deepCompareEqualsForMaps = (a: any, b: any) => {
	if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
		return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
	}

	return a === b;
};

const useDeepCompareMemoize = (value: any) => {
	const ref = React.useRef();

	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
};

const useDeepCompareEffectForMaps = (callback: React.EffectCallback, dependencies: any[]) => {
	useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default Map;
