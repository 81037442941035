import { useNavigate } from 'react-router-dom';
import { Button, Grid, Paper, Box, Typography, Stack } from '@mui/material';
import EmptyStatesClient from '../../clients/EmptyStatesClient';
import { Features } from '../../types';
import { useRecoilValue } from 'recoil';
import userState from '../../atoms/user-state';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ArrowCircleRight } from '@mui/icons-material';

interface Props {
	pageIdentifier: Features;
	banner: {
		title: string;
		cta: {
			label: string;
			href?: string;
			onClick?: () => void;
		};
		video: string;
	};
	items?: {
		title: string;
		subtitle: string;
	}[];
	brands?: any[];
	categories?: string[];
}

const EmptyState = (props: Props) => {
	const navigate = useNavigate();
	const intl = useIntl();

	const { pageIdentifier, banner, items, brands, categories } = props;
	const user = useRecoilValue(userState);
	const carouselRef = useRef<HTMLDivElement>(null);

	const onClickCta = async () => {
		await EmptyStatesClient.markAsviewed(pageIdentifier, user, user.token);

		if (banner.cta.onClick) {
			banner.cta.onClick();
		} else if (banner.cta.href) {
			navigate(banner.cta.href);
		}
	};

	useEffect(() => {
		if (brands && brands.length > 0 && carouselRef.current) {
			const carousel = carouselRef.current;
			let scrollAmount = 0;
			const scrollInterval = setInterval(() => {
				if (carousel.scrollWidth - carousel.clientWidth === scrollAmount) {
					scrollAmount = 0;
				} else {
					scrollAmount += 1;
				}
				carousel.scrollLeft = scrollAmount;
			}, 50);

			return () => clearInterval(scrollInterval);
		}
	}, [brands, brands?.length]);

	return (
		<Grid>
			<Paper
				sx={{
					px: {
						md: '2.8em',
						xs: '2em',
					},
					py: {
						md: '1.8em',
						xs: '1em',
					},
					backgroundColor: '#EAEAEA',
					borderRadius: '0.7rem 0.7rem 0 0',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						flexDirection: { md: 'row', xs: 'column' },
						justifyContent: {
							md: 'space-between',
							xs: 'start',
						},
					}}
				>
					<Box
						className="flex flex-col max-w-[21rem] justify-end gap-8"
						sx={{
							py: { md: 2, xs: 1 },
						}}
					>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Typography variant="h2" className="leading-snug font-semibold ">
								{banner.title}
							</Typography>
						</Box>

						<Button
							variant="contained"
							className="text-base leading-8 max-w-60"
							sx={{
								maxWidth: {
									md: '240px',
									xs: '100%',
								},
							}}
							onClick={onClickCta}
							href={banner.cta.href}
						>
							{banner.cta.label}
						</Button>
					</Box>
					<Box className="flex mx-[6%] w-full justify-end">
						<iframe
							style={{ borderRadius: '2em', width: '100%', maxWidth: '500px' }}
							height="260"
							src={banner.video}
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							allowFullScreen
						></iframe>
					</Box>
				</Box>
			</Paper>
			{items && (
				<Paper
					sx={{
						py: '3rem',
						borderRadius: '0 0 11.2px 11.2px',
						px: {
							md: '2.8em',
							xs: '2em',
						},
					}}
					elevation={0}
				>
					<Grid container justifyContent={'space-between'} sx={{ textAlign: { md: 'initial', xs: 'center' } }}>
						{items.map((item) => (
							<Grid item md={3.33} sx={{ paddingBottom: { xs: '1em' } }}>
								<Typography variant="h6" className="font-bold">
									{item.title}
								</Typography>
								<Typography variant="body2" className="text-base mt-4">
									{item.subtitle}
								</Typography>
							</Grid>
						))}
					</Grid>
				</Paper>
			)}
			{brands && (
				<Paper
					sx={{
						borderRadius: '0 0 11.2px 11.2px',
					}}
					className="pt-2 pb-4 md:px-4 xs:px-2"
					elevation={0}
				>
					<Box className="w-full overflow-hidden">
						<Box
							ref={carouselRef}
							className="flex overflow-x-scroll"
							sx={{
								'&::-webkit-scrollbar': {
									display: 'none',
								},
							}}
						>
							{brands.map((brand, index) => (
								<img
									src={brands[index]?.Customer_Logo ?? ''}
									alt={brands[index]?.Name ?? ''}
									className="flex-shrink-0 w-24 h-14 object-contain mx-10"
								/>
							))}
						</Box>
						<Stack rowGap={2} className="pt-8">
							<Typography variant="h3">{intl.formatMessage({ id: 'new_quotation.empty_state.title' })}</Typography>
							<Typography variant="subtitle1" className="font-normal">
								{intl.formatMessage({ id: 'new_quotation.empty_state.subtitle' })}
							</Typography>
							<Box className="flex items-center flex-wrap">
								{categories?.map((category, index) => (
									<Box key={`category-${index}-${category}`} className="flex items-center gap-2 w-2/6 my-4">
										<ArrowCircleRight className="text-gray-300" />
										<Typography variant="subtitle1" className="font-normal">
											{category}
										</Typography>
									</Box>
								))}
							</Box>
						</Stack>
					</Box>
				</Paper>
			)}
		</Grid>
	);
};

export default EmptyState;
