import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	Theme,
	SxProps,
	TableSortLabel,
	IconButton,
	Tooltip,
	CardProps,
	Grid,
} from '@mui/material';
import Counter from './counter';
import { IntlShape, useIntl } from 'react-intl';
import Card, { Action } from './card';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const FixedTable = styled(Table)`
	table-layout: fixed;
`;

const NoPaddingCell = styled(TableCell)`
	padding: 5px;
`;

const CellContent = styled('div')`
	overflow: hidden;
	text-overflow: ellipsis;
`;

const CollapsedHeader = styled(Typography)`
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export interface DataColumn {
	displayName?: string;
	supportsMobile?: boolean;
	headerStyle?: SxProps<Theme>;
	sort?: (a: DataRow, b: DataRow) => number;
	backgroundColor?: (row: DataRow) => any;
	render: (row: DataRow, intl: IntlShape) => any;
	showWarning?: (row: DataRow, intl: IntlShape) => boolean;
}

export interface DataRow {
	[key: string]: any;
}

interface Props extends CardProps {
	title: string;
	subtitle?: React.ReactElement | string;
	borderRadius?: number;
	actions?: Action[];
	columns: DataColumn[];
	disabledWidthForUnsortedColumns?: boolean;
	rows?: DataRow[];
	avatar?: boolean;
	loading?: boolean;
	error?: boolean;
	emptyMessage?: string;
	maxTableHeight?: string;
	onRetry?: () => void;
	mobileOnClick?: (row: DataRow) => void;
	infiniteScroll?: boolean;
}

const getSortedRows = (columns: DataColumn[], direction: 'asc' | 'desc', rows?: DataRow[], orderBy?: string) => {
	if (!rows) {
		return rows;
	}

	if (!orderBy) {
		return rows;
	}

	const column = columns.filter((column) => column.displayName === orderBy)[0];
	if (!column) {
		return rows;
	}

	const sort = column.sort;
	if (!sort) {
		return rows;
	}

	return rows.sort((a, b) => (direction === 'asc' ? sort(a, b) : sort(b, a)));
};

const TableCard = (props: Props) => {
	const {
		title,
		subtitle,
		borderRadius,
		actions,
		columns,
		disabledWidthForUnsortedColumns,
		rows,
		avatar,
		loading,
		error,
		emptyMessage,
		infiniteScroll,
		onRetry,
		mobileOnClick,
		maxTableHeight,
		...rest
	} = props;

	const intl = useIntl();
	let ref = useRef<HTMLDivElement | null>(null);

	const [orderBy, setOrderBy] = useState<string>();
	const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
	const [columnAverageWidth, setColumnAverageWidth] = useState(100);

	const handleChangeSorting = (column?: string) => {
		if (!column) {
			return;
		}

		if (orderBy !== column) {
			setOrderBy(column);
			setDirection('asc');
		} else if (direction === 'asc') {
			setDirection('desc');
		} else {
			setDirection('asc');
		}
	};
	useEffect(() => {
		if (ref.current) {
			setColumnAverageWidth(ref.current.offsetWidth / columns.length);
		}
	}, [ref, columns.length]);

	const sortedRows = getSortedRows(columns, direction, rows, orderBy);

	return (
		<Card
			title={title}
			subtitle={subtitle}
			borderRadius={borderRadius}
			loading={loading}
			error={error}
			onRetry={onRetry}
			avatar={avatar && <Counter value={rows?.length || 0} />}
			actions={actions}
			{...rest}
		>
			<Grid container minHeight={'160px'} maxHeight={maxTableHeight || 'auto'} sx={{ overflowX: 'hidden', overflowY: 'overlay' }} spacing={2} justifyContent="space-between">
				<Grid item xs={12}>
					<FixedTable stickyHeader >
						<TableHead>
							<TableRow>
								{!!rows?.length &&
									columns.map((column, index) => (
										<NoPaddingCell
											key={index}
											colSpan={1}
											sx={{
												...column.headerStyle,
												display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
											}}
										>
											{column.displayName && (
												<CellContent>
													{column.sort && (
														<TableSortLabel
															active={orderBy === column.displayName}
															direction={orderBy === column.displayName ? direction : 'asc'}
															onClick={() => handleChangeSorting(column.displayName)}
														>
															<Tooltip title={column.displayName} placement="top">
																<CollapsedHeader variant="body2" color="grey.600" width={`${columnAverageWidth}px`}>
																	{column.displayName}
																</CollapsedHeader>
															</Tooltip>
														</TableSortLabel>
													)}
													{!column.sort && (
														<Tooltip title={column.displayName} placement="top">
															<CollapsedHeader
																variant="body2"
																color="grey.600"
																width={disabledWidthForUnsortedColumns ? undefined : `${columnAverageWidth}px`}
															>
																{column.displayName}
															</CollapsedHeader>
														</Tooltip>
													)}
												</CellContent>
											)}
										</NoPaddingCell>
									))}
							</TableRow>
						</TableHead>
						<TableBody>
							{!!sortedRows?.length &&
								sortedRows?.map((row, i) => (
									<TableRow
										key={i}
										sx={{ cursor: { xs: mobileOnClick ? 'pointer' : 'auto', md: 'auto' } }}
										onClick={
											mobileOnClick
												? () => {
													mobileOnClick(row);
												}
												: undefined
										}
									>
										{columns.map((column, ind) => (
											<NoPaddingCell
												key={ind}
												sx={{
													display: { xs: column.supportsMobile ? 'table-cell' : 'none', md: 'table-cell' },
													background: column.backgroundColor ? column.backgroundColor(row) : 'inherit',
												}}
											>
												<CellContent>
													{column.render(row, intl) != null ? <span>{column.render(row, intl)}</span> : '-'}
													{column.showWarning ? (
														column.showWarning(row, intl) ? (
															<Tooltip
																placement="top"
																title={intl.formatMessage({ id: 'home.seller.tooltip_warning.message' })}
															>
																<>
																	<IconButton disableRipple color="error" sx={{ mb: 0.5, cursor: 'default' }}>
																		<WarningAmberOutlinedIcon></WarningAmberOutlinedIcon>
																	</IconButton>
																</>
															</Tooltip>
														) : (
															''
														)
													) : (
														''
													)}
												</CellContent>
											</NoPaddingCell>
										))}
									</TableRow>
								))}
							{!sortedRows?.length && (
								<TableRow>
									<NoPaddingCell
										colSpan={columns.length}
										align="center"
										sx={{
											borderBottom: 'none',
										}}
									>
										<CellContent>
											<Typography mt={4}>
												{loading && intl.formatMessage({ id: 'common.loading' })}
												{!loading && (emptyMessage || intl.formatMessage({ id: 'common.table.empty_message' }))}
											</Typography>
										</CellContent>
									</NoPaddingCell>
								</TableRow>
							)}
						</TableBody>
					</FixedTable>
				</Grid>
			</Grid>
		</Card>
	);
};

export default TableCard;
