import { Box, Divider, Grid, Stack, StackProps, Tooltip, Typography } from '@mui/material';
import { StackedData } from '../../types';

interface stackDataDisplayProps extends StackProps {
	title?: string;
	descriptionTextAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	data?: StackedData[];
}

const StackDataDisplay: React.FC<stackDataDisplayProps> = ({
	title,
	data,
	descriptionTextAlign = 'inherit',
	...props
}) => {
	return (
		<Stack {...props}>
			{title && <Typography className="pr-20 font-extrabold">{title}</Typography>}
			{data?.map((item, index) => (
				<Grid container flexGrow={1} key={item.title}>
					{(index > 0 || title) && (
						<Grid item xs={12}>
							<Divider sx={{ my: '0.9rem' }} orientation="horizontal"></Divider>
						</Grid>
					)}

					<Grid container alignItems={'center'}>
						<Grid item xs={6}>
							{item.objectTitle ? (
								item.objectTitle
							) : (
								<Typography pr={'0.5rem'} variant="body2" color="grey.600" fontWeight={600}>
									{item.title}
								</Typography>
							)}
						</Grid>
						<Grid item xs={6}>
							{item.objectDesc ? (
								item.objectDesc
							) : (
								<Typography variant="body2" whiteSpace={'pre-line'} textAlign={descriptionTextAlign}>
									{item.description}
								</Typography>
							)}
						</Grid>

						{item.extraFullRow &&
							(item.extraFullRowTooltip ? (
								<Tooltip title={item.extraFullRowTooltip}>
									<Grid pt={'0.7rem'} item xs={12}>
										{item.extraFullRow}
									</Grid>
								</Tooltip>
							) : (
								<Grid pt={'0.7rem'} item xs={12}>
									{item.extraFullRow}
								</Grid>
							))}
					</Grid>
				</Grid>
			))}
		</Stack>
	);
};

export default StackDataDisplay;
